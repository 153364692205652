import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import { useModuleContext, useModuleContextSetter } from '00.app/module/moduleContext';
import { useStore } from '30.quickConnect.Stores';

const useHomeData = (): void => {
  // On récupère le store
  const {
    LoginStore: { availableModules },
  } = useStore();

  const module = useModuleContext();
  const setModule = useModuleContextSetter();

  useEffectOnce(() => {
    let hasAccess = false;
    if (!availableModules) {
      setModule(AppModulesIDs.MODULE_WEB_CLIENT_ID, true);
      return;
    }
    Object.keys(availableModules).forEach((key) => {
      if (availableModules[key] === AppModulesIDs.MODULE_PARAM_WORK_SITE_ID) {
        hasAccess = true;
      }
    });
    if (!hasAccess) {
      setModule(AppModulesIDs.MODULE_WEB_CLIENT_ID, true);
      return;
    }
    if (module !== AppModulesIDs.MODULE_PARAM_WORK_SITE_ID) {
      setModule(AppModulesIDs.MODULE_PARAM_WORK_SITE_ID);
    }
  });
};

export default useHomeData;
