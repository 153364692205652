import React, { FunctionComponent } from 'react';
import { Button, ButtonProps, Stack } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { WORK_SITE_SUMMARY_USERS, WORK_SITE_SUMMARY_WBS } from '10.quickConnect.app/routes/paths';

const NavigationButtons: FunctionComponent = () => {
  const { t } = useTranslation('moduleWorkSite');
  const navigate = useNavigate();
  const { workSiteId } = useParams();
  const buttonProps: ButtonProps = {
    endIcon: <ChevronRight />,
    variant: 'contained',
    fullWidth: true,
    style: {
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
    },
  };

  return (
    <Stack gap={'.5rem'}>
      <Button
        onClick={() => navigate(WORK_SITE_SUMMARY_USERS.replace(':workSiteId', workSiteId || ''))}
        {...buttonProps}
      >
        {t('work_site_summary_users')}
      </Button>
      <Button onClick={() => navigate(WORK_SITE_SUMMARY_WBS.replace(':workSiteId', workSiteId || ''))} {...buttonProps}>
        {t('work_site_summary_work_part_wbs')}
      </Button>
    </Stack>
  );
};

export default NavigationButtons;
