import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { CommandType } from '../types';
import { UseDataProps } from './types';
import { ItemData, DeclarationContext } from '90.quickConnect.Models/models';
import { FORMS } from '10.quickConnect.app/routes/paths';
import { useStore } from '30.quickConnect.Stores';
import { useNavigatorOnLine } from '80.quickConnect.Core/hooks';

const useData = (
  handleItemDatas: (commandType: CommandType) => Promise<[boolean, string | undefined, ItemData]>,
  transferDeclaration: (newItemData: ItemData | undefined) => Promise<boolean>,
  context: DeclarationContext,
): UseDataProps => {
  const { t } = useTranslation('declaration');
  const {
    DeclarationStore: {
      isMaxAttachment,
      sendDeclarationToAPIAsync,
      setEditableDeclaration,
      setIsEditingCurrentDeclaration,
      setIsUseDeclarationComponent,
      removeBackUpDcl,
      resetStore: dclResetStore,
    },
    QCScriptStore: { resetStore: qcsResetStore },
    ConsentStore: { resetStore: consentResetStore },
  } = useStore();
  const navigate = useNavigate();
  const onLine = useNavigatorOnLine();

  const onTransfer = useCallback(async () => {
    const [goToNextStep, nextStepIndex, newItemData] = await handleItemDatas(CommandType.Transfer);
    if (!isMaxAttachment) {
      const declarationTransfered = await transferDeclaration(newItemData);
      if (declarationTransfered) {
        if (goToNextStep && nextStepIndex && newItemData && newItemData.Workflow) {
          newItemData.Workflow.currentStep = nextStepIndex;
          if (onLine) {
            sendDeclarationToAPIAsync(t);
          }
        } else {
          removeBackUpDcl(context.formId);
          setIsUseDeclarationComponent(false);
          setIsEditingCurrentDeclaration(false);
          setEditableDeclaration(false);
          dclResetStore();
          qcsResetStore();
          consentResetStore();
          navigate(FORMS, { replace: true });
        }
      }
    } else {
      toast.warn(`${t('attachment_fields_errors_has_max_count_for_form')} (60)`);
    }
  }, [
    handleItemDatas,
    isMaxAttachment,
    transferDeclaration,
    onLine,
    sendDeclarationToAPIAsync,
    removeBackUpDcl,
    context.formId,
    setIsUseDeclarationComponent,
    setIsEditingCurrentDeclaration,
    setEditableDeclaration,
    dclResetStore,
    qcsResetStore,
    consentResetStore,
    navigate,
    t,
  ]);

  return {
    t,
    onTransfer,
  };
};

export default useData;
