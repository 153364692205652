import React, { FunctionComponent, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { IDetectedBarcode, IScannerProps, Scanner } from '@yudiel/react-qr-scanner';
import { useTranslation } from 'react-i18next';
import { SynchronizeEquipmentReturnType } from '90.quickConnect.Models/enums';
import { useStore } from '30.quickConnect.Stores';
import { EntityData } from '90.quickConnect.Models/models';

type ScannerStandaloneProps = Omit<IScannerProps, 'onScan'> & { onScanResult: (data: EntityData | undefined) => void };

const ScannerStandalone: FunctionComponent<ScannerStandaloneProps> = (props: ScannerStandaloneProps) => {
  const {
    EquipmentsStore: { synchronizeEquipmentsAsync },
  } = useStore();
  const { t: tAxios } = useTranslation('axios');

  const searchEquipmentByFamily = (family: string): void => {
    if (family) {
      synchronizeEquipmentsAsync(family, '', '', SynchronizeEquipmentReturnType.All, undefined, tAxios).then(
        (newData) => props.onScanResult(newData),
      );
    }
  };

  const searchEquipmentById = (id?: string): void => {
    if (id) {
      //Ajout du tag "##" au code d'équipement pour le différentier côté API du QRcode
      synchronizeEquipmentsAsync(`##${id}`, '', '', SynchronizeEquipmentReturnType.All, undefined, tAxios).then(
        (newData) => props.onScanResult(newData),
      );
    }
  };

  const handleScan = (result: IDetectedBarcode[]) => {
    if (result && result.length > 0) {
      const text = result[0].rawValue;
      if (text) {
        // Format: UUID/UUID
        const familyCodeRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

        if (familyCodeRegex.test(text.replace('\\', '/'))) {
          searchEquipmentByFamily(text);
          return;
        }
        searchEquipmentById(text);
      }
    }
  };

  const [scannerError, setScannerError] = useState<string>('');
  return (
    <Box data-cy="ScannerStandalone">
      {<Typography color={'error'}>{scannerError}</Typography>}
      <Scanner
        styles={{ container: { maxWidth: '90vw' } }}
        {...props}
        onScan={handleScan}
        onError={(e) => {
          setScannerError((e as Error).message);
          if (props.onError) {
            props.onError(e);
          }
        }}
      />
    </Box>
  );
};

export default ScannerStandalone;
