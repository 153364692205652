import { action, makeAutoObservable, observable } from 'mobx';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { TFunction } from 'i18next';
import ViewStore from '30.quickConnect.Stores/RootStore/ViewStore';
import { AbortRequestsStore } from '30.quickConnect.Stores/RootStore/interfaces';
import {
  GetSearchFieldValuesAADResponse,
  GetSearchFieldValuesResponse,
  SynchronizeEquipmentResponse,
  SynchronizeEquipmentsResponse,
} from '30.quickConnect.Stores/RootStore/EquipmentsStore/Payloads/responses';
import {
  GetSearchFieldValuesAADRequest,
  GetSearchFieldValuesBySchemaIdAndEntityInstanceIdRequest,
  GetSearchFieldValuesRequest,
  SynchronizeEquipmentMultipleRequest,
  SynchronizeEquipmentRequest,
} from '30.quickConnect.Stores/RootStore/EquipmentsStore/Payloads/requests';
import {
  API_GET_SEARCH_ADD_QUERY,
  API_GET_SEARCH_FIELD_VALUES,
  API_SYNCHRONIZE_EQUIPMENT,
  API_SYNCHRONIZE_EQUIPMENTS,
} from '40.quickConnect.DataAccess/axios/apiRoutes';
import {
  DeclarationViewer,
  EntityData,
  SearchFamillyData,
  FieldDesc,
  EditDesc,
  DataSourceField,
} from '90.quickConnect.Models/models';
import {
  mapDeclarationViewer,
  mapEntitiesData,
  mapQCHistoricalData,
  mapSearchValues,
} from '90.quickConnect.Models/mappings';
import { FieldType, SynchronizeEquipmentReturnType } from '90.quickConnect.Models/enums';
import { mapEntityData, mapEntityInstanceSearch, mapQCDocument } from '90.quickConnect.Models/mappings';
import { KeyValuePair } from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import equipmentsDb from '40.quickConnect.DataAccess/indexedDb/dbs/equipmentsDb';
import mapUserAADSearch from '90.quickConnect.Models/mappings/fields/mapUserAADSearch';
import { OptionsRender } from '50.quickConnect.Fields/FieldsTypes/Inputs/TextQcField/types';
import RootStore from '30.quickConnect.Stores/RootStore';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import { errorHandler, makeUniqueArray } from '80.quickConnect.Core/helpers';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

class EquipmentsStore implements AbortRequestsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/EquipmentsStore/index.ts';

  clientHTTP: IClientHTTP;

  synchronizingEquipments = false;

  equipments: EntityData[] = [];

  searchFamillyResults: SearchFamillyData[] = [];

  historicalDataViewer: ViewStore<DeclarationViewer>;

  // Pour la gestion de l'entity en mode Deep Link
  entityInstanceIdDeepLink: string | undefined;

  // Pour la gestion d'erreurs sur les champs notification et texte, on stocker un tableau avec le fullPathId du champ comme clé et un booléen en valeur (TRUE si l'élément choisi fait partie de la liste, FALSE sinon)
  isInDataSource: Map<string, boolean> = new Map();

  // Ne pas mettre dans resetStore
  shouldAbortRequests = false;

  // Pour la gestion des listes partagées
  bindingSharedLists: KeyValuePair<string, string | undefined>[] = [];

  logger: CustomLogger;

  RootStore: RootStore;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.logger = logger;
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;
    makeAutoObservable(
      this,
      {
        synchronizingEquipments: observable,
        equipments: observable,
        isInDataSource: observable,
        searchFamillyResults: observable,
        shouldAbortRequests: observable,
        historicalDataViewer: observable,
        entityInstanceIdDeepLink: observable,
        bindingSharedLists: observable,
        setEntityInstanceIdDeepLink: action.bound,
        setShouldAbortRequests: action,
        synchronizeEquipmentsAsync: action,
        setSearchFamillyResults: action.bound,
        setBindingSharedLists: action,
        getEntityData: action,
        setEquipments: action,
        setSynchronizingEquipments: action.bound,
        getEquipmentsFromDb: action.bound,
        resetIsInDataSource: action.bound,
        findIsInDataSource: action.bound,
        deleteIsInDataSource: action.bound,
        setHistoricalDataViewer: action.bound,
      },
      { autoBind: true },
    );
    this.historicalDataViewer = new ViewStore<DeclarationViewer>(this.logger, 'HistoricalDataViewer');
  }

  setEntityInstanceIdDeepLink = (entityInstanceIdDeepLink: string | undefined) => {
    this.entityInstanceIdDeepLink = entityInstanceIdDeepLink;
  };

  resetIsInDataSource = (): void => {
    this.isInDataSource = new Map();
  };

  resetStore = (): void => {
    this.synchronizingEquipments = false;
    this.equipments = [];
    this.searchFamillyResults = [];
    this.resetIsInDataSource();
    this.entityInstanceIdDeepLink = undefined;
  };

  deleteIsInDataSource = (fullPathId: string): void => {
    try {
      if (this.isInDataSource.delete(fullPathId))
        throw new TypeError(`key not found in DataSource for this fullPathId: ${fullPathId}`);
    } catch (error: unknown) {
      if (error instanceof TypeError) this.logger.info(EquipmentsStore.TAG, error.message);

      errorHandler(EquipmentsStore.TAG, error, 'deleteIsInDataSource');
    }
  };

  findIsInDataSource = (fullPathId: string): boolean => {
    const result = this.isInDataSource.get(fullPathId);
    return !!result;
  };

  isEmptyDataSourceForcedSelection = (field: FieldDesc): FieldDesc => {
    const { fullPathId, fieldType } = field;
    if (fieldType !== FieldType.Text) return field;

    const { dataSource } = field as EditDesc;
    if (!dataSource) return field;

    const { forcedSelection } = dataSource;
    if (!forcedSelection) return field;

    return this.isInDataSource.has(fullPathId) && !this.isInDataSource.get(fullPathId)
      ? { ...field, value: '' }
      : field;
  };

  setIsInDataSource = (fullPathId: string, value: boolean): void => {
    this.isInDataSource.set(fullPathId, value);
  };

  setSearchFamillyResults = (searchFamillyResults: SearchFamillyData[]) =>
    (this.searchFamillyResults = searchFamillyResults);

  setBindingSharedLists = (bindingSharedLists: KeyValuePair<string, string | undefined>[]) =>
    (this.bindingSharedLists = bindingSharedLists);

  setSynchronizingEquipments = (synchronizingEquipments: boolean) =>
    (this.synchronizingEquipments = synchronizingEquipments);

  setShouldAbortRequests = (shouldAbortRequests: boolean) => (this.shouldAbortRequests = shouldAbortRequests);

  setEquipments = (equipments: EntityData[]) => (this.equipments = equipments);

  setHistoricalDataViewer = (data: ViewStore<DeclarationViewer>) => {
    this.historicalDataViewer = data;
  };

  synchronizeEquipmentsAsync = async (
    qrCode: string,
    mustBeOfSchema: string | undefined,
    mustHaveQCTag: string | undefined,
    queryInfo: SynchronizeEquipmentReturnType | undefined,
    withPropertyOrder: boolean | undefined,
    t: TFunction,
  ): Promise<EntityData | undefined> => {
    this.synchronizingEquipments = true;
    try {
      const { language } = window.navigator;
      let request = {
        qrCode,
        mustBeOfSchema: mustBeOfSchema ?? '',
        mustHaveQCTag: mustHaveQCTag ?? '',
        queryInfo: queryInfo ?? '',
        languageCode: language.substring(0, 2),
      } as SynchronizeEquipmentRequest;

      if (withPropertyOrder) request = { ...request, withPropertyOrder };
      if (!this.shouldAbortRequests) {
        const response = await this.clientHTTP.post<SynchronizeEquipmentResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(API_SYNCHRONIZE_EQUIPMENT),
          request,
          {
            withCredentials: true,
          },
        );

        const { getSharedLists } = this.RootStore.SharedListStore;
        let sharedLists = this.RootStore.SharedListStore.sharedLists ?? [];
        if (response.data.bindingSharedLists) {
          // Extract the list IDs, filtering out any undefined values
          const listIds: string[] = [];
          // Safely extract the list IDs from the KeyValuePair objects
          for (const key in response.data.bindingSharedLists) {
            if (Object.prototype.hasOwnProperty.call(response.data.bindingSharedLists, key)) {
              const value = response.data.bindingSharedLists[key];
              if (value !== undefined) {
                listIds.push(value as unknown as string);
              }
            }
          }

          const unloadedSharedLists = listIds.filter(
            (listId) => !sharedLists.some((sharedList) => sharedList.id === listId),
          );

          if (unloadedSharedLists.length > 0) {
            await getSharedLists(unloadedSharedLists);
            sharedLists =
              this.RootStore.SharedListStore.sharedLists?.filter((sharedList) => listIds.includes(sharedList.id)) ?? [];
          }
        }

        if (!response.data?.data?.id) {
          throw new Error('Équipement inconnue');
        }
        // On fait les mapping
        const equipmentReceived = mapEntityData(response.data.data, response.data.binding, response.data.bindingTypes);

        if (queryInfo === SynchronizeEquipmentReturnType.All) {
          equipmentReceived.qcDocuments = response.data.documents.map(mapQCDocument);
          equipmentReceived.historicalData = response.data.historicalData.datas.map(mapQCHistoricalData);
          this.logger.log(EquipmentsStore.TAG, `equipmentReceived.HistoricalData: ${equipmentReceived.historicalData}`);
          const historicalDataViewer = equipmentReceived.historicalData.map((d) => mapDeclarationViewer(d, false));
          this.historicalDataViewer?.setItems(historicalDataViewer);
        }
        // On upd ate le store
        const dbItem = await equipmentsDb.equipments.get({ id: equipmentReceived.id });
        if (dbItem) {
          await equipmentsDb.equipments.put(equipmentReceived);
        } else {
          await equipmentsDb.equipments.add(equipmentReceived);
        }
        await this.getEquipmentsFromDb();
        this.synchronizingEquipments = false;
        this.setBindingSharedLists(response.data.bindingSharedLists);
        return equipmentReceived;
      }
    } catch (error) {
      let errorMsg = '';
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<GetSearchFieldValuesResponse>;
        errorMsg = errorAxios?.response
          ? `${errorAxios?.response?.data?.message}`
          : `${errorAxios?.code ?? 'Invalid HTTP code status'} ${errorAxios?.message}`;
      } else if (error instanceof Error) {
        errorMsg = `${t('failed_synchronizeEquipments_msg').toString()}: ${error.message}`;
      } else {
        errorMsg = `${t('failed_synchronizeEquipments_msg').toString()}: Erreur inconnue`;
      }

      errorHandler(EquipmentsStore.TAG, error, 'synchronizeEquipmentsAsync');

      this.synchronizingEquipments = false;

      toast.error(
        t('synchronizeEquipments_async_error_with_msg', {
          msg: errorMsg,
        }).toString(),
      );
    }
    this.synchronizingEquipments = false;
    return undefined;
  };

  /**
   * Permet de faire une requête sur plusieurs équipements en même temps (notamment utile pour le deepLink)
   *
   * @param qcCodes
   * @returns {PromiseLike<void>}
   */
  synchronizeEquipementsMultipleAsync = async (
    fullIdsAndqrCodes: Record<string, string>,
  ): Promise<Record<string, { instanceId: string; schemaId: string }> | undefined> => {
    try {
      const qrCodes = makeUniqueArray(Object.values(fullIdsAndqrCodes));

      if (qrCodes.length === 0) return;
      this.synchronizingEquipments = true;
      // on crée la requête
      const syncEquipmentsRequest = {
        qrCodes,
        queryInfo: SynchronizeEquipmentReturnType.Attributs,
        languageCode: window.navigator.language.substring(0, 2),
      } as SynchronizeEquipmentMultipleRequest;

      // Traitement de la requête
      const syncEquipmentsResponse = await this.clientHTTP.post<SynchronizeEquipmentsResponse>(
        API_SYNCHRONIZE_EQUIPMENTS,
        syncEquipmentsRequest,
        { withCredentials: true },
      );

      this.logger.info(EquipmentsStore.TAG, 'Recupération des data OK');

      // Mappings
      const {
        data: { allData, schemasInfos },
      } = syncEquipmentsResponse;

      const equipmentsReceived: EntityData[] = mapEntitiesData(allData, schemasInfos);

      this.logger.info(EquipmentsStore.TAG, 'Mapping des equipements OK');

      const result: Record<string, { instanceId: string; schemaId: string }> = Object.keys(fullIdsAndqrCodes).reduce(
        (
          acc: Record<string, { instanceId: string; schemaId: string }>,
          currentKey: string,
        ): Record<string, { instanceId: string; schemaId: string }> => {
          acc[currentKey] = { instanceId: '', schemaId: '' };
          return acc;
        },
        {},
      );

      // On update le store
      await Promise.all(
        equipmentsReceived.map(async (equipmentReceived: EntityData) => {
          const dbItem = await equipmentsDb.equipments.get({ id: equipmentReceived.id });
          if (dbItem) {
            await equipmentsDb.equipments.put(equipmentReceived);
          } else {
            await equipmentsDb.equipments.add(equipmentReceived);
          }

          const keysToChange = Object.keys(fullIdsAndqrCodes).filter((key) =>
            StringExtension.isTheSame(fullIdsAndqrCodes[key], equipmentReceived.code),
          );

          keysToChange.forEach((keyToChange: string) => {
            result[keyToChange] = { instanceId: equipmentReceived.id, schemaId: equipmentReceived.entitySchemaId };
          });
          this.logger.info(EquipmentsStore.TAG, 'Enregistrement en localDB OK');
        }),
      );

      return result;
    } catch (error: unknown) {
      errorHandler(EquipmentsStore.TAG, error, 'synchronizeEquipementsMultipleAsync');
    } finally {
      this.synchronizingEquipments = false;
    }
  };

  getEntityData = async (schemaId: string, entityId: string): Promise<EntityData | undefined> => {
    this.logger.resetInitDateTimeApp();
    if (this.equipments.length === 0) await this.getEquipmentsFromDb();
    const entityData = this.equipments.find((e) => e.id === entityId && e.entitySchemaId === schemaId);
    return entityData;
  };

  getEntityDataByCodeName = async (code: string): Promise<EntityData | undefined> => {
    try {
      if (this.equipments.length === 0) await this.getEquipmentsFromDb();
      return this.equipments.find(
        (e: EntityData) => e.code.localeCompare(code, undefined, { sensitivity: 'base' }) === 0,
      );
    } catch (error) {
      errorHandler(EquipmentsStore.TAG, error, 'getEntityDataByCodeName');
    }
  };

  async getEquipmentsFromDb() {
    const localArray = await equipmentsDb.equipments.toArray();
    this.setEquipments(localArray);
  }

  /**
   * Permet de vérifier si l'élément est bien présent dans la liste des éléments de recherche.
   * @param value Chaine de caratère à rechercher dans le tableau des résultats de recherche des familles
   * @return true si l'item est présent, false sinon
   */
  checkItemInSearchValuesList = (value: string): boolean => {
    return this.searchFamillyResults.find((item) => item.title === value) ? true : false;
  };

  async getSearchFieldValues(
    idDataSource: string,
    idsAttributesSearch: string[],
    idsAttributesSelect: string[],
    searchText: string,
    t: TFunction,
    searchAllUO = false,
    addSearchAttributResult = false,
  ) {
    try {
      const request = {
        idDataSource,
        idsAttributesSearch,
        idsAttributesSelect,
        searchText,
        searchAllUO,
        addSearchAttributResult,
      } as GetSearchFieldValuesRequest;
      const response = await this.clientHTTP.post<GetSearchFieldValuesResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(API_GET_SEARCH_FIELD_VALUES),
        request,
        {
          withCredentials: true,
        },
      );
      const mapped = response.data.data.map(mapEntityInstanceSearch);

      const newSearchFamillyResults = mapSearchValues(mapped, idsAttributesSelect);

      this.setSearchFamillyResults(newSearchFamillyResults);

      return { newSearchFamillyResults, thereIsMoreItems: response.data.isThereMoreItems };
    } catch (error) {
      errorHandler(EquipmentsStore.TAG, error, 'getSearchFieldValues');
      this.synchronizingEquipments = false;
    }
    return undefined;
  }

  /**
   * Recherche les contacts dans l'AAD
   * @param searchPattern donnée à rechercher
   * @param t TFunction
   * @param fieldNames Optionnel
   * @returns
   */
  async getSearchFieldValuesAzureAD(
    searchPattern: string,
    t?: TFunction,
    fieldNames: string[] | null = ['userPrincipalName', 'displayName', 'mail', 'givenName', 'surname'],
  ) {
    try {
      const request = {
        searchPattern,
        fieldNames,
      } as GetSearchFieldValuesAADRequest;

      const response = await this.clientHTTP.post<GetSearchFieldValuesAADResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(API_GET_SEARCH_ADD_QUERY),
        request,
        {
          withCredentials: true,
        },
      );

      const mapped =
        response.data.users?.map(mapUserAADSearch).map((d): OptionsRender => {
          const data = new Map<string, string>();
          data.set('login', d.userPrincipalName);
          return { title: d.displayName, description: d.mail, id: d.id, value: d.mail, data };
        }) ?? [];

      // this.setSearchFamillyResults(mapped);

      return { searchUsersResults: mapped, thereIsMoreItems: response.data.isThereMoreItems };
    } catch (error) {
      errorHandler(EquipmentsStore.TAG, error, 'getSearchFieldValuesAzureAD');
      this.synchronizingEquipments = false;
    }
    return undefined;
  }

  async getSearchFieldValuesBySchemaAndEntityInstance(
    dataSource: DataSourceField,
    valueInternalData: string,
    valueField: string,
    t: TFunction,
  ): Promise<boolean | never> {
    try {
      // Séparation schemaId / EntityInstanceId
      const ids = valueInternalData.split(new RegExp('[\\/\\\\]'));

      if (ids.length !== 2)
        throw new Error(
          `EquipementStore.ts - getSearchFieldValuesBySchemaAndEntityInstance method failed: le schemaId et l'entityInstanceId ne sont pas récupérables avec la chaine de caractère ${valueInternalData} (séparation par "/" ou "\\").`,
        );

      const [entitySchemaId, entityInstanceId] = ids;
      const { idDataSource, addSearchAttributResult, idsAttributesSearch, idsAttributesSelect } = dataSource;

      const request = {
        idDataSource,
        entityInstanceId,
        entitySchemaId,
        idsAttributesSearch,
        idsAttributesSelect,
        addSearchAttributResult,
      } as GetSearchFieldValuesBySchemaIdAndEntityInstanceIdRequest;

      const response = await this.clientHTTP.post<GetSearchFieldValuesResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(API_GET_SEARCH_FIELD_VALUES),
        request,
        {
          withCredentials: true,
        },
      );

      const mapped = response.data.data.map(mapEntityInstanceSearch);

      const newSearchFamillyresults = mapSearchValues(mapped, idsAttributesSelect);

      // return !newSearchFamillyresults.some(
      //   (searchFamillyResult: SearchFamillyData) => searchFamillyResult.value === valueField,
      // );
      return false;
    } catch (error) {
      errorHandler(EquipmentsStore.TAG, error, 'getSearchFieldValuesBySchemaAndEntityInstance');

      return false;
    }
  }
}

export default EquipmentsStore;
