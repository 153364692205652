import React, { useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { QrCode } from '@mui/icons-material';
import { QrCodeScanName } from './const';
import useData from './hooks';
import ScannerStandalone from './ScannerStandalone';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';
import CenterItems from '10.quickConnect.app/components/shared/LayOut/TopBar/CenterItems';
import {
  ListColumnOrRowContainer,
  TabsMainBox,
  ListColumnContainer,
} from '10.quickConnect.app/components/domain/Home/styles';

const QRCodeScan = () => {
  const { t } = useTranslation('qrCode');
  const { code, setCode, searchEquipment, handleScan } = useData();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <Box sx={{ width: { md: '80vw', lg: '80vw' }, margin: { md: '1em auto', lg: '1em auto' } }}>
      <Box data-cy={QrCodeScanName} sx={TabsMainBox}>
        <Box sx={ListColumnOrRowContainer(theme)}>
          <Box sx={ListColumnContainer}>
            <CenterItems showLeftMenu={false} pageTitle={t('qcapp_qrcode_scan_title')} searchView={true} />
            <Stack direction={'row'} width="100%" flexGrow={1} alignItems={'center'} maxHeight={'10rem'}>
              <SearchBar
                toolTip={t('search_bar')}
                filterInputValue={code}
                setFilter={setCode}
                filter={code}
                applyFilter={searchEquipment}
                clearFilter={() => setCode('')}
                setSearchIconToRight={false}
              />
              <IconButton onClick={() => setShowDialog(true)} color={'primary'}>
                <QrCode />
              </IconButton>
            </Stack>
            <Backdrop open={false}>
              <CircularProgress />
            </Backdrop>
          </Box>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)} keepMounted={false}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{t('qcapp_qrcode_scan_title')}</span>
              <IconButton onClick={() => setShowDialog(false)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
              <ScannerStandalone onScanResult={handleScan} />
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};
export default observer(QRCodeScan);
