/**
 * Filter a given list of object and return a new reference.
 * Check every string attributs of each object and filter with the given string.
 * Not case-sensitive
 * @param searchStr String to search in the object list
 * @param listToSearch List to filter
 */

export function filterFromString(searchStr: string, listToSearch: Array<any>): Array<any> {
  return listToSearch.filter(
    (element) =>
      Object.keys(element).filter((attrKey) => {
        const attr: never = element[attrKey] as never;
        if (typeof attr === 'string' && attrKey !== 'id') {
          return (attr as string).toLowerCase().includes(searchStr.toLowerCase());
        }
      }).length > 0,
  );
}
