import React, { useState } from 'react';
import { CommonSimpleSelectable } from '../../typesWorkSite';
import { useWorkSiteContext } from '../../WorkSiteSummaryWrapper';
import { AddNewUserDialogProps } from './ChooseNewOrExistingAddDialog';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { useStore } from '30.quickConnect.Stores';

type AddOneOrMultipleUsersDialogProps = {
  toggleEntity: (e: React.MouseEvent<HTMLTableRowElement>, id: string) => void;
  filteredEntities: Array<CommonSimpleSelectable & { agency: string }>;
  agencyList: Array<string>;
  filterAgency?: string;
  changeFilterAgency?: (e: React.SyntheticEvent, value: string) => void;
  nameFilter: string;
  filterPerName?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  addExistingEntity: () => void;
  onCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type SelectableUsersWithAgency = Array<CommonSimpleSelectable & { agency: string }>;

const useAddOneOrMultipleUsersDialogData = (props: AddNewUserDialogProps): AddOneOrMultipleUsersDialogProps => {
  const { WorkSiteStore } = useStore();
  const { workSite } = useWorkSiteContext();
  const [agencyList, setAgencyList] = useState<Array<string>>([]);
  const [entities, setEntities] = useState<SelectableUsersWithAgency>([]);
  const [filterAgency, setFilterAgency] = useState<string>(workSite.agency || '');
  const [nameFilter, setNameFilter] = useState<string>('');

  // We need to compute it every rerender to make sure all updates are taken into account (filters & select)
  const filteredEntities = entities.filter((user) => {
    return user.agency === filterAgency && user.name.toLowerCase().includes(nameFilter.toLowerCase());
  });

  // Add "select" attr to all elements
  useEffectOnce(() => {
    WorkSiteStore.getAllUsers().then((res) =>
      setEntities(
        res.map((user) =>
          (props.baseData.filter((wsUser) => wsUser.id === user.id) || []).length > 0
            ? {
                ...user,
                selected: true,
              }
            : { ...user, selected: false },
        ),
      ),
    );
    WorkSiteStore.getAllAgencyName().then((res) => setAgencyList(res));
  });

  function toggleEntity(e: React.MouseEvent<HTMLTableRowElement>, id: string) {
    setEntities(entities.map((entity) => (entity.id === id ? { ...entity, selected: !entity.selected } : entity)));
  }

  function changeFilterAgency(e: React.SyntheticEvent, value: string) {
    setFilterAgency(value);
  }

  function filterPerName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setNameFilter(e.target.value);
  }

  // Select all elements display at the screen (hence the use of filteredEntities instead of entities)
  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEntities(filteredEntities.map((entity) => ({ ...entity, selected: e.target.checked })));
  };

  function addExistingEntity() {
    const listToAdd = entities.filter((entity) => entity.selected);
    props.onAdd(listToAdd);
    props.onClose();
  }

  return {
    toggleEntity,
    filteredEntities,
    agencyList,
    filterAgency,
    changeFilterAgency,
    filterPerName,
    nameFilter,
    addExistingEntity,
    onCheckAll,
  };
};

export default useAddOneOrMultipleUsersDialogData;
