import { parseISO } from 'date-fns';
import sharedListsDb from '40.quickConnect.DataAccess/indexedDb/dbs/sharedListsDb';
import {
  AllFieldValueTypes,
  AttributeValue,
  Choice,
  ComboDesc,
  EntityData,
  FieldDesc,
  HierarchicalChoice,
  NotificationTarget,
  QCNotification,
} from '90.quickConnect.Models/models';
import {
  SharedListType,
  ChoiceProperties,
  FieldType,
  NotificationType,
  EntitySchemaAttributeTypes,
} from '90.quickConnect.Models/enums';
import { errorHandler, flatten } from '80.quickConnect.Core/helpers';
import { EquipementProperty } from '90.quickConnect.Models/enums/declarations/equipementProperty';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';
import { isAnArrayOfChoice, isChoice } from '90.quickConnect.Models/guards';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';

const tag = '80.quickConnect.Core/helpers/common.ts';

/** Permet de rechercher une valeur dans un objet avec une clé insensible a la casse */
export const getValueByInsensitiveKey = (obj: object, key: string): unknown => {
  // Cas où l'object est vide...
  if (Object.keys(obj).length === 0) return undefined;
  const keySensitive = Object.keys(obj).find((k: string) => k.toUpperCase() === key.toUpperCase());

  return keySensitive ? (obj as any)[keySensitive] : undefined;
};

/** Permet de vérifier si l'objet passé est bien de type Notification */
export const isQCNotification = (item: any): item is QCNotification => {
  if (item === undefined || item === null) return false;
  if (typeof item !== 'object' || Array.isArray(item)) return false;

  return (
    'autoSend' in item &&
    'notificationType' in item &&
    'searchAllUO' in item &&
    'selectedTargets' in item &&
    'sendToMe' in item &&
    'subject' in item
  );
};

/** Permet de verifier si l'objet passé est de type QCNotification Version 2  */
export const isNotificationDataPartial = (obj: any): obj is Partial<QCNotification> => {
  if (obj === undefined || obj === null) return false;
  if (typeof obj !== 'object' && Array.isArray(obj)) return false;

  return (
    'autoSend' in obj ||
    'notificationType' in obj ||
    'searchAllUO' in obj ||
    'selectedTargets' in obj ||
    'sendToMe' in obj ||
    'subject' in obj
  );
};

/**
 * Permet de créer un Tableau de NotificationTarget.
 * @param emails
 * @returns
 */
export const createContactFromArray = (emails: string): NotificationTarget[] =>
  emails
    .replace(',', ';')
    .split(';')
    .reduce(
      (acc: NotificationTarget[], current: string) =>
        /^\w+([.-_]?\w+)*@\w+([.-_]?\w+)*(.\w{2,3})+$/.test(current.trim())
          ? [...acc, { alias: null, target: current.trim() } as NotificationTarget]
          : acc,
      [],
    );

/**
 * Permet de créer un notificationData depuis une chaine de caractère.
 * @param serialized
 * @param autoSend
 * @returns
 */
export const parseNotificationData = (
  serialized: string,
  autoSend: boolean | undefined,
): QCNotification | undefined => {
  if (serialized && serialized !== '') {
    const serializedTrim = serialized.trim();

    // Elément unique { "autoSend": false, "notificationType": 1, "selectedTargets": [ { "title": "Eric Ballot", "value": "eric.ballot.c2s@gmail.com" }], "sendToMe": false, "subject": null, "searchAllUO":false }
    // ou sendToMe ¤ xxxx@xxxx.xxx; yyyy@yyyy.yyy; zzzz@zzzz.zzz ¤ searchAllUO
    // ou simplement une chaine "xxxx@xxxx.xxx; yyyy@yyyy.yyy; zzzz@zzzz.zzz"
    if (serializedTrim.startsWith('{')) {
      try {
        const notificationData = JSON.parse(serializedTrim);

        if (!isNotificationDataPartial(notificationData))
          throw new Error("L'objet donné n'est pas de type NotificationData");

        return {
          autoSend: notificationData.autoSend ?? autoSend ?? true,
          notificationType: notificationData.notificationType ?? NotificationType.Email,
          searchAllUO: notificationData.searchAllUO,
          selectedTargets: notificationData.selectedTargets ?? [],
          sendToMe: notificationData.sendToMe ?? false,
          subject: notificationData.subject ?? null,
        };
      } catch (error) {
        errorHandler(tag, error, 'parseNotificationData');

        return undefined;
      }
    } else {
      if (serializedTrim.includes('¤')) {
        const notification = serializedTrim.split('¤');

        if (notification.length === 3) {
          const sendToMe = Boolean(notification[0].trim());
          const selectedTargets = createContactFromArray(notification[1]);
          const searchAllUO = Boolean(notification[2].trim());

          return {
            notificationType: NotificationType.Email,
            subject: null,
            autoSend: autoSend ?? true,
            sendToMe,
            selectedTargets,
            searchAllUO,
          } as QCNotification;
        } else {
          const sendToMe = Boolean(notification[0]);
          const selectedTargets = createContactFromArray(notification[1]);

          return {
            notificationType: NotificationType.Email,
            subject: null,
            autoSend: autoSend ?? true,
            searchAllUO: false,
            sendToMe,
            selectedTargets,
          } as QCNotification;
        }
      } else {
        const selectedTargets = createContactFromArray(serializedTrim.trim());

        return {
          notificationType: NotificationType.Email,
          subject: null,
          autoSend: autoSend ?? true,
          searchAllUO: false,
          sendToMe: false,
          selectedTargets,
        } as QCNotification;
      }
    }
  }

  return undefined;
};

/** Permet de vérifier si le paramètre passé peut être converti en date */
export const isDate = (dateValue: AllFieldValueTypes): dateValue is Date =>
  (dateValue instanceof Date && !isNaN(dateValue.getTime())) ||
  (typeof dateValue === 'string' && parseISO(dateValue) instanceof Date && !isNaN(Date.parse(dateValue)));

/** Permet de vérifier si le paramètre passé peut être converti en date */
export const isDateTimeExtension = (dateValue: AllFieldValueTypes): dateValue is DateTimeExtension =>
  dateValue instanceof DateTimeExtension;

/** Permet de vérifier si la valeur passé en paramètre est bien un nombre sous chaine de caractères
 * @example <caption>Number('1234') = 1234 Number('9BX9') = NaN</caption>
 */
export const isNumeric = (numValue: AllFieldValueTypes): numValue is number | string => {
  const reg = new RegExp(/^-?[0-9]+\.?([0-9]+)*?$/, 'g');

  return (
    (typeof numValue === 'number' && !Number.isNaN(numValue)) || (typeof numValue === 'string' && reg.test(numValue))
  );
};

/** Permet de savoir si le nombre choisi est un float */
export const isFloat = (numValue: number): numValue is number => !Number.isInteger(numValue);

export const getChoicesListFromListDef = async (listDef?: string): Promise<Choice[]> => {
  try {
    if (!listDef) return [] as Choice[];
    // Récupération de la liste
    const sharedList = (await sharedListsDb.sharedLists.toArray()).find((sl) => sl.id === listDef);

    if (!sharedList) return [] as Choice[];

    const { hierarchicalChoices, data, listType } = sharedList;

    return listType === SharedListType.Hierarchical ? flatten(hierarchicalChoices, (c) => c.children) : data;
  } catch (error) {
    errorHandler(tag, error, 'getChoicesListFromListDef');

    return [];
  }
};

/** Renvoie une chaine de caractères en recherchant dant le dictionnaire de la prop data de l'objet Choice */
export const findValueProperties = (
  choiceField: Choice | HierarchicalChoice,
  referencesProperties: string[] | undefined,
  displayString: boolean,
): string => {
  if (!choiceField) return '';
  const { data, label, value } = choiceField;
  return referencesProperties
    ? referencesProperties!
        .reduce((acc: string[], current: string): string[] => {
          const currentTrim = current.trim();
          switch (currentTrim.toUpperCase()) {
            case ChoiceProperties.Value.toUpperCase():
              return [...acc, value];
            case ChoiceProperties.Label.toUpperCase():
              return [...acc, label];
            default:
              let valueToUse: string | number | boolean;
              if (typeof data === 'string') {
                // On est en mode CSV
                const x = data.split(',');
                valueToUse = x[+currentTrim - 1];
              } else if (data) {
                const nextValueToInclude = getValueByInsensitiveKey(data, currentTrim);
                valueToUse =
                  typeof nextValueToInclude === 'string' ||
                  typeof nextValueToInclude === 'number' ||
                  typeof nextValueToInclude === 'boolean'
                    ? nextValueToInclude
                    : StringExtension.convertFromObjectWithFormat(nextValueToInclude, '');
              } else {
                valueToUse = '';
              }

              return valueToUse
                ? [
                    ...acc,
                    displayString
                      ? StringExtension.convertFromObjectWithFormat(valueToUse!, '')
                      : valueToUse.toString(),
                  ]
                : acc;
          }
        }, [])
        .join(', ')
    : label;
};

export const getValueFromChoiceProperties = (
  checkboxListValues: Choice[] | HierarchicalChoice[],
  referencesProperties: string[] | undefined,
  displayString = false,
): string[] =>
  checkboxListValues.map((checkboxListValue: Choice) =>
    findValueProperties(checkboxListValue, referencesProperties, displayString),
  );

export const getValueFromChoice = (
  choiceValues: Choice[],
  listChoice: Choice[] | undefined,
  fieldType: FieldType,
): Choice | Choice[] => {
  const isHierarchicalChoice = (choice: Choice | HierarchicalChoice): choice is HierarchicalChoice => {
    return 'childrenLabels' in choice && 'hierarchicalLabel' in choice;
  };

  const result = listChoice
    ? listChoice.flatMap((choice: Choice | HierarchicalChoice) => {
        return choiceValues.flatMap((choiceValue: Choice | HierarchicalChoice) => {
          if (isHierarchicalChoice(choice)) {
            const matchedChildren: HierarchicalChoice[] = [];
            const checkChildren = (child: HierarchicalChoice, label: string) => {
              if (child.label.localeCompare(label, undefined, { sensitivity: 'base' }) === 0) {
                matchedChildren.push(child);
              }
              if (child.children && child.children.length > 0) {
                child.children.forEach((c) => checkChildren(c, label));
              }
            };

            checkChildren(choice, choiceValue.label);
            return matchedChildren;
          } else {
            if (
              choice.value.localeCompare(choiceValue.value, undefined, { sensitivity: 'base' }) === 0 ||
              choice.label.localeCompare(choiceValue.label, undefined, { sensitivity: 'base' }) === 0
            ) {
              return [choice];
            }
            return [];
          }
        });
      })
    : [];

  switch (fieldType) {
    case FieldType.Combo:
    case FieldType.Alert:
    case FieldType.RadioList:
      return result[0];

    case FieldType.CheckBoxList:
    default:
      return result;
  }
};

const isEntityData = (element: any): element is EntityData => {
  return Object.keys(element).some((k: string) => k === 'code' || k === 'title');
};

const getEquipementValues = (property: string, equipement: EntityData): string | AttributeValue => {
  switch (property.toUpperCase()) {
    case EquipementProperty.CODE:
      return equipement.code;
    case EquipementProperty.TITLE:
      return equipement.title;
    default:
      const attrToSearch = property.includes(':') ? property.substring(0, property.lastIndexOf(':')) : property;
      return (
        equipement.attributes.find(
          (v) =>
            v.attributeLabel.toUpperCase() === attrToSearch.toUpperCase() ||
            v.attributeId.localeCompare(attrToSearch, undefined, { sensitivity: 'base' }) === 0,
        ) ?? ''
      );
    // }
  }
};

const getValueOrLabelreferenciesAttributes = (choices: Choice[], referenceProperty: string): string =>
  choices
    .map((choice: Choice) => {
      if (!referenceProperty.includes(':')) return choice.label;

      const [refProp, choiceProp] = referenceProperty.split(':');

      if (choiceProp.toLowerCase() === 'label' || choiceProp.toLowerCase() === 'value')
        return choice[choiceProp.toLowerCase() as keyof Choice];
    })
    .join(', ');

export const getDataSourceValue = (
  dataSource: unknown,
  asDisplayString: boolean,
  referencesProperties?: string[],
): AllFieldValueTypes => {
  if (!dataSource || !referencesProperties) return undefined;

  if (referencesProperties.length > 0) {
    if (isEntityData(dataSource)) {
      // On est dans le cas d'un EntityData
      const entityRequestedValues = referencesProperties.map((referenceProperty: string) => {
        const equipmentVal = getEquipementValues(referenceProperty, dataSource);
        // On est dans le cas d'un attribut de type chaine de caractère
        if (typeof equipmentVal === 'string') {
          return equipmentVal;
        }
        // On est dans le cas d'un attribut de type liste
        if (
          equipmentVal.type === EntitySchemaAttributeTypes.SharedList ||
          equipmentVal.type === EntitySchemaAttributeTypes.SharedListHierarchical
        ) {
          const { value } = equipmentVal;
          // Le cas - où on aurait typé en liste mais que la valeur est une chaine de caractère (ex: seeder)
          if (typeof value === 'string') {
            return value;
          }
          // On est dans le cas d'une liste de choix
          const valuesToDisplay = Array.isArray(value) ? value : [value];
          return getValueOrLabelreferenciesAttributes(valuesToDisplay as Choice[], referenceProperty);
        }

        return equipmentVal.value?.toString() ?? '';
      });

      return asDisplayString
        ? entityRequestedValues.map((entityRequestedValue: string) => entityRequestedValue).join(', ')
        : entityRequestedValues.length > 0
        ? (entityRequestedValues[0] as AllFieldValueTypes)
        : (entityRequestedValues as AllFieldValueTypes);
    } // TODO à Implémenter: Sinon dans une DataSource
  }
};

export const getChoicesFromString = (
  searchValue: string,
  listChoice: Choice[],
  fieldType: FieldType,
): Choice | Choice[] | undefined => {
  switch (fieldType) {
    case FieldType.Combo:
    case FieldType.RadioList:
      return listChoice.find(
        (choice: Choice) =>
          choice.label.toUpperCase() === searchValue.toUpperCase() ||
          choice.value.toUpperCase() === searchValue.toUpperCase(),
      );

    case FieldType.HierarchicalList:
      return flatten(listChoice as HierarchicalChoice[], (i) => i.children)
        .filter(
          (hchoice) =>
            StringExtension.isTheSame(hchoice.label, searchValue) ||
            StringExtension.isTheSame(hchoice.value, searchValue),
        )
        .map((c) => ({
          label: c.label,
          value: c.value,
          data: c.data,
        }));

    case FieldType.CheckBoxList:
      return listChoice.filter(
        (choice: Choice) =>
          choice.label.toUpperCase() === searchValue.toUpperCase() ||
          choice.value.toUpperCase() === searchValue.toUpperCase(),
      );
  }
};

export const getChoicesFromChoice = (
  choices: Choice | Choice[],
  listChoice: Choice[],
  fieldType: FieldType,
): Choice | Choice[] | undefined => {
  switch (fieldType) {
    case FieldType.Combo:
    case FieldType.RadioList: {
      const choiceToFind: Choice | undefined = isAnArrayOfChoice(choices)
        ? choices[0]
        : isChoice(choices)
        ? choices
        : undefined;
      return listChoice.find(
        (choice: Choice) =>
          choice.label.toUpperCase() === choiceToFind?.label.toUpperCase() ||
          choice.value.toUpperCase() === choiceToFind?.value.toUpperCase(),
      );
    }

    case FieldType.HierarchicalList:
    case FieldType.CheckBoxList: {
      const choicesToFind: Choice[] = isAnArrayOfChoice(choices) ? choices : isChoice(choices) ? [choices] : [];

      return listChoice.reduce((choicesToSend: Choice[], currentChoice: Choice) => {
        const choiceFound: boolean = choicesToFind.some(
          (choiceToFind: Choice) =>
            choiceToFind.label.toUpperCase() === currentChoice.label.toUpperCase() ||
            choiceToFind.value.toUpperCase() === currentChoice.value.toUpperCase(),
        );

        return choiceFound ? [...choicesToSend, currentChoice] : choicesToSend;
      }, []);
    }
  }
};

export const getChoiceList = async (fieldWithReference: FieldDesc): Promise<Choice[]> => {
  try {
    const { listChoice: listChoiceFromExp } = fieldWithReference as ComboDesc;
    const { listDef } = fieldWithReference as ComboDesc;

    if (listChoiceFromExp) return listChoiceFromExp;

    // On récupère les choicesList par le listDef
    const listChoicesBylistDef = await getChoicesListFromListDef(listDef);
    return listChoicesBylistDef as unknown as Choice[];
  } catch (error) {
    errorHandler(tag, error, 'getChoiceList');
    return [];
  }
};

export const typeCheck = <TTo, TFrom = TTo>(arg: TFrom): TTo => arg as unknown as TTo;

export const findChoices = (listChoiceFromReferenceField: Choice[], valueToCheck: unknown): Choice[] => {
  if (Array.isArray(valueToCheck)) {
    return listChoiceFromReferenceField.reduce((acc: Choice[], current: Choice) => {
      const result = valueToCheck.find((v: any) => {
        return (
          (v && v.label && v.label.toUpperCase() === current.label.toUpperCase()) ||
          (v && v.value && v.value.toUpperCase() === current.value.toUpperCase())
        );
      });
      return result ? [...acc, current] : acc;
    }, []);
  }

  return [];
};

/**
 * Permet de spliter des chaines de caractères contenu dans un tableau en fonction d'un pattern particulier. Chaque élément sera trim et renvoyé dans un nouveau tableau.
 * @param res
 * @param pattern
 * @returns
 */
export const splitAndTrim = (res: string[], pattern = new RegExp('[,;]', 'g')): string[] =>
  res.reduce((acc: string[], current: string): string[] => {
    const splitString: string[] = current
      .split(pattern)
      .map((address: string) => address.trim())
      .filter((value, index, array) => array.indexOf(value) === index);
    return [...acc, ...splitString];
  }, []);
