import { List, ListItem, ListItemText, Typography, Box, Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NamedUserParameterValue } from '90.quickConnect.Models/models';
import { SignInResponse } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';

interface UserProfileProps {
  setOpenChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
  signInInfos: SignInResponse;
}
const UserProfile = ({ setOpenChangePassword, signInInfos }: UserProfileProps) => {
  const { t } = useTranslation('userSetting');

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      subheader={<Typography sx={{ color: '#FF8C00' }}>{t('qcapp_preference_basic_profil')}</Typography>}
    >
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_user_upn')}
          secondary={
            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
              {signInInfos.userUPN}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" />
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_user_first_name')}
          secondary={
            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
              {signInInfos.firstName}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" />

      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_user_last_name')}
          secondary={
            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
              {signInInfos.lastName}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" />

      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_user_job')}
          secondary={
            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
              {signInInfos.job}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" />

      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_user_organisation')}
          secondary={
            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
              {signInInfos.organizationalUnitName}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" />

      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_user_phone')}
          secondary={
            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
              {signInInfos.phone}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" />
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_user_email')}
          secondary={
            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
              {signInInfos.email}
            </Typography>
          }
        />
      </ListItem>

      {signInInfos?.userParameterValue?.map((val: NamedUserParameterValue) => (
        <Box key={val.userParameterId}>
          <Divider variant="fullWidth" />
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
              primary={val.name}
              secondary={
                <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                  {val.value ? t('qccore_true_label') : t('qccore_false_label')}
                </Typography>
              }
            />
          </ListItem>
        </Box>
      ))}
      <Divider variant="fullWidth" />
      <ListItem onClick={() => setOpenChangePassword(true)}>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_title_dialog_change_password')}
        />
      </ListItem>
    </List>
  );
};
export default UserProfile;
