import { ForgottenPasswordAvailableMethodsEnum } from '10.quickConnect.app/components/domain/Login/types';
import { BaseResponse } from '40.quickConnect.DataAccess/axios/baseResponse';
import { AADConfig, NamedUserParameterValue, OrganizationalUnitInfo } from '90.quickConnect.Models/models';
import { OrganizationalUnitViewer } from '90.quickConnect.Models/models/user/organizationalUnitViewer';

/**
 * Réponse d'authentification
 */
export type SignInResponse = BaseResponse & {
  userUPN: string;
  authenticationMethod: number;
  availableModules?: Modules;
  isLoggedIn: boolean;
  firstName: string;
  lastName: string;
  job: string;
  organizationalUnitName: string;
  organizationalUnitId: string;
  organizationalUnitCode: string;
  phone: string;
  email: string;
  rights: number;
  customerName: string;
  subscribedFeatures: any;
  providerSettings: AADConfig;
  userParameterValue: NamedUserParameterValue[];
  errorCode?: string;
  visiblesOrganizationalUnits: OrganizationalUnitViewer[];
  module: AppModulesIDs;
  emailSupport?: string;
};

export enum AppModulesIDs {
  EMPTY_ID = '',
  MODULE_WEB_CLIENT_ID = '00000000-0000-0000-0000-000000000000',
  MODULE_PARAM_WORK_SITE_ID = 'a8bb701e-a94d-496d-bd9f-3f3c5e9cb88e',
}

export type Modules = {
  [name: string]: AppModulesIDs;
};

export type ValidateTokenResponse = {
  statusCode: number;
};

/**
 * Réponse de déconnection
 */
export type SignOutResponse = BaseResponse & {
  wasLoggedIn: boolean;
  isLoggedOut: boolean;
};

/**
 * Réponse de dispatcher
 */
export type DispatcherResponse = BaseResponse & {
  availableEnvironment: [
    {
      customerName: string;
      urlQuickConnect: string;
    },
  ];
};

export type ForgottenPasswordResponse = BaseResponse & {
  availableMethods: ForgottenPasswordAvailableMethodsEnum;
};

export type GetUserParameterValuesResponse = BaseResponse & {
  userParameterValues: NamedUserParameterValue[];
};

export type GetCurrentUserOrganizationalUnitsResponse = BaseResponse & {
  organizationalUnits: OrganizationalUnitInfo[];
};
