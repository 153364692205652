import { v4 as uuidv4 } from 'uuid';
import { FieldDesc } from '90.quickConnect.Models/models';

const updateErrorsFieldOrChild = (
  fullPathId: string,
  field: FieldDesc,
  errors: string[],
  invalidItemsMsg: string,
): [FieldDesc, boolean] => {
  let changeMade = false;
  if (field.fullPathId == fullPathId && field.errors !== errors) {
    field.errors = errors;
    // field.stateId = uuidv4();
    changeMade = true;
  }
  // Vérification des erreurs dans les enfants
  let hasChildErrors = false;
  if (field.items && field.items.length > 0) {
    field.items.forEach((child, index) => {
      const [updatedChild, childChanged] = updateErrorsFieldOrChild(fullPathId, child, errors, invalidItemsMsg);
      if (childChanged) {
        changeMade = true;
        // field.errors = errors?.filter((errorMsg: string) => !field.errors?.includes(errorMsg));
      }
      field.items[index] = updatedChild;
    });

    // Vérifier si au moins un enfant a des erreurs
    hasChildErrors = field.items.some((child) => child.errors && child.errors.length > 0);
  }

  if (hasChildErrors) {
    if (!field.errors?.includes(invalidItemsMsg)) {
      field.errors = [...(field.errors ?? []), invalidItemsMsg];
    }
  } else {
    // Supprimer invalidItemsMsg si plus aucun enfant n'a d'erreurs
    field.errors = field.errors?.filter((error) => error !== invalidItemsMsg) ?? [];
  }

  return [field, changeMade];
};

export default updateErrorsFieldOrChild;
