import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Arrow, Ball } from './icons';
import {
  CardActionsStyle,
  CardMediaStyle,
  CardStyle,
  CguBoxStyle,
  CguLinkStyle,
  CustomInputLabelStyle,
  CustomTextFieldStyle,
  MainBoxStyle,
  QcLogoStyle,
  TypoAppVersionStyle,
  TypoStyle,
} from './styles';
import useLoginData from './hooks';
import { LoginName } from './const';
import ForgetPassword from './ForgetPassword';
import ResetMethod from './ForgetPassword/ResetMethod';
import { SignInReducerActionsType } from './types';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';

const { NODE_ENV, REACT_APP_ENV, REACT_APP_VERSION } = process.env;
export const appVersion =
  NODE_ENV !== 'production' ? `${REACT_APP_ENV} version ${REACT_APP_VERSION}` : `version ${REACT_APP_VERSION}`;

const Login = () => {
  const { t } = useTranslation('login');

  const {
    signInRequest: { userUPN, password, cgu },
    setSignInRequest,
    connect,
    showPassword,
    setShowPassword,
    isLogging,
    isOpenBackdrop,
    showPasswordField,
    forgetPassword,
    onForgetPassword,
    availableModules,
    availableMethods,
    method,
    setMethod,
    onSelectMethod,
    selectMethodPage,
    resetPasswordPage,
    onGoBackToLogin,
    confirmPassword,
    newPassword,
    setConfirmPassword,
    setNewPassword,
    resetCode,
    setResetCode,
    passwordRules,
    setPasswordRules,
    resetPasswordData,
    onResetPassword,
    resetCodeLoader,
    currentModule,
    emailSupport,
  } = useLoginData(t);

  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Box data-cy={LoginName} sx={MainBoxStyle}>
      <Arrow />
      {!isMobile && <Ball />}
      <Card sx={CardStyle}>
        <form id="login" onSubmit={(e) => e.preventDefault()}>
          <CardMedia
            component="img"
            alt="france-piste-cyclable-transfrontaliere"
            image="/assets/logo_QC_medium_standard_HD.png"
            style={QcLogoStyle}
            sx={CardMediaStyle}
          />
          <Typography sx={TypoAppVersionStyle(theme.breakpoints)}>{appVersion}</Typography>
          <CardContent>
            {!forgetPassword ? (
              <>
                <Typography variant="body2" sx={TypoStyle}>
                  {showPasswordField ? t('qcapp_login_description_upn_and_pwd') : t('qcapp_login_description_upn')}
                </Typography>
                <CustomInputLabelStyle>{t('qcapp_login_hint_identifier')}</CustomInputLabelStyle>

                <CustomTextFieldStyle variant="outlined" fullWidth>
                  <TextField
                    data-cy="userUPN-input"
                    margin="none"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus={!showPasswordField}
                    value={userUPN}
                    onChange={(event: React.ChangeEvent<{ value: string }>) =>
                      setSignInRequest({
                        type: SignInReducerActionsType.USERUPN,
                        value: event.target.value,
                      })
                    }
                  />
                </CustomTextFieldStyle>
                <Slide direction="left" in={showPasswordField} mountOnEnter unmountOnExit>
                  <Box>
                    <Box>
                      <CustomInputLabelStyle htmlFor="outlined-adornment-password">
                        {t('qcapp_login_hint_pwd')}
                      </CustomInputLabelStyle>
                      <CustomTextFieldStyle variant="outlined" fullWidth>
                        <TextField
                          data-cy="password-input"
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          autoFocus={showPasswordField}
                          onChange={(event: React.ChangeEvent<{ value: string }>) =>
                            setSignInRequest({
                              type: SignInReducerActionsType.PASSWORD,
                              value: event.target.value,
                            })
                          }
                          required
                          autoComplete="password"
                          slotProps={{
                            input: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={() => setShowPassword(!showPassword)}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />
                      </CustomTextFieldStyle>
                    </Box>
                    {Object.keys(availableModules).length > 1 && (
                      <Box>
                        <CustomInputLabelStyle htmlFor="outlined-adornment-password">
                          {t('qcapp_choose_module')}
                        </CustomInputLabelStyle>
                        <CustomTextFieldStyle variant="outlined" fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            defaultValue={currentModule}
                            value={currentModule}
                            onChange={(select) => {
                              setSignInRequest({
                                type: SignInReducerActionsType.MODULE,
                                value: select.target.value as AppModulesIDs,
                              });
                            }}
                          >
                            {Object.keys(availableModules).map((moduleKey) => {
                              const value = availableModules[moduleKey];
                              return (
                                <MenuItem key={value} value={value}>
                                  {moduleKey}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </CustomTextFieldStyle>
                      </Box>
                    )}
                  </Box>
                </Slide>
                <Box sx={CguBoxStyle}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cgu}
                        data-cy="cgu-checkbox"
                        onChange={(event: React.ChangeEvent<{ checked: boolean }>) =>
                          setSignInRequest({
                            type: SignInReducerActionsType.CGU,
                            value: event.target.checked,
                          })
                        }
                      />
                    }
                    label={t('qcapp_login_privacy_policy_check_title')}
                  />
                  <Link color="inherit" variant="body2" href={t('qcapp_show_privacy').toString()} sx={CguLinkStyle}>
                    {t('qcapp_login_privacy_policy_button_title')}
                  </Link>
                </Box>
              </>
            ) : (
              <>
                {selectMethodPage && !resetPasswordPage && (
                  <ResetMethod availableMethods={availableMethods} setMethod={setMethod} method={method} />
                )}
                <>
                  {resetPasswordPage && (
                    <ForgetPassword
                      resetPasswordData={resetPasswordData}
                      setNewPassword={setNewPassword}
                      resetCode={resetCode}
                      newPassword={newPassword}
                      setResetCode={setResetCode}
                      confirmPassword={confirmPassword}
                      setConfirmPassword={setConfirmPassword}
                      passwordRules={passwordRules}
                      setPasswordRules={setPasswordRules}
                    />
                  )}
                </>
                <Backdrop open={resetCodeLoader}>
                  <CircularProgress />
                </Backdrop>
              </>
            )}
          </CardContent>
          <CardActions sx={CardActionsStyle(showPasswordField)}>
            {showPasswordField && !forgetPassword && (
              <>
                <Link
                  href="#"
                  underline="always"
                  onClick={() => {
                    onForgetPassword();
                  }}
                >
                  {t('qcapp_login_forgot_password')}
                </Link>
                {emailSupport && emailSupport.trim() !== '' && (
                  <Link href={`mailto:${emailSupport}`} underline="always">
                    {t('qcapp_email_support')}
                  </Link>
                )}
              </>
            )}
            {!forgetPassword && (
              <Button
                data-cy="login-btn"
                variant="contained"
                type="submit"
                onClick={connect}
                disabled={isLogging}
                endIcon={<ArrowForward />}
              >
                {t('qcapp_login_bt_connexion')}
              </Button>
            )}
            {forgetPassword && (
              <Button
                data-cy="back-btn"
                variant="contained"
                type="submit"
                onClick={onGoBackToLogin}
                disabled={isLogging}
                startIcon={<ArrowBackIcon />}
              >
                {t('forget_password.back_btn')}
              </Button>
            )}
            {forgetPassword && !resetPasswordPage && (
              <Button
                data-cy="next-btn"
                variant="contained"
                type="submit"
                onClick={method ? onSelectMethod : undefined}
                endIcon={<ArrowForward />}
              >
                {t('forget_password.next_btn')}
              </Button>
            )}
            {resetPasswordPage && (
              <Button
                data-cy="reset-btn"
                variant="contained"
                type="submit"
                onClick={onResetPassword}
                endIcon={<ArrowForward />}
              >
                {t('forget_password.reset_btn')}
              </Button>
            )}
          </CardActions>
        </form>
      </Card>
      <Backdrop open={isOpenBackdrop}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default observer(Login);
