import { AxiosError } from 'axios';
import { PaletteMode } from '@mui/material';
import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import RootStore from '30.quickConnect.Stores/RootStore';
import { API_GET_API_INFORMATION } from '40.quickConnect.DataAccess/axios/apiRoutes';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { AppInformationResponse } from '30.quickConnect.Stores/RootStore/UserSettingsStore/Payloads/responses';
import { OrganizationalUnitInfo, QCErrorHTTP } from '90.quickConnect.Models/models';
import { errorHandler } from '80.quickConnect.Core/helpers';

class UserSettingsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/UserSettingsStore/index.ts';

  clientHTTP: IClientHTTP;

  formsAsTree = true;

  inboxesAsTree = true;

  inboxesAsAlphaNumericSort = true;

  declarationsAsTree = true;

  currentOU: string | undefined;

  userOUs: OrganizationalUnitInfo[] = [];

  logger: CustomLogger;

  RootStore: RootStore;

  muiMode: PaletteMode = 'dark';

  syncDate = '';

  constructor(rootStore: RootStore, logger: CustomLogger, storageKey: string) {
    this.logger = logger;
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;
    makeAutoObservable(
      this,
      {
        formsAsTree: observable,
        inboxesAsTree: observable,
        inboxesAsAlphaNumericSort: observable,
        declarationsAsTree: observable,
        muiMode: observable,
        currentOU: observable,
        userOUs: observable,

        switchMuiMode: action,
        setFormsAsTree: action,
        setInboxesAsTree: action,
        setInboxesAsAlphaNumericSort: action,
        setDeclarationsAsTree: action,
        setCurrentOU: action,
        setUserOUs: action,
      },
      { autoBind: true },
    );
    void makePersistable(this, {
      name: storageKey,
      properties: [
        'formsAsTree',
        'inboxesAsTree',
        'inboxesAsAlphaNumericSort',
        'declarationsAsTree',
        'muiMode',
        'syncDate',
      ],
      storage: window.localStorage,
    });
  }

  resetStore = (): void => {
    this.formsAsTree = true;
    this.inboxesAsTree = true;
    this.inboxesAsAlphaNumericSort = true;
    this.declarationsAsTree = true;
    this.muiMode = 'dark';
    this.syncDate = '';
    this.currentOU = undefined;
    this.userOUs = [];
  };

  setSyncDate = (dt: Date) => {
    this.syncDate = DateTimeExtension.setISOFormat(dt);
  };

  switchMuiMode = (): void => {
    this.muiMode = this.muiMode === 'light' ? 'dark' : 'light';
  };

  setFormsAsTree = (formsAsTree: boolean): void => {
    this.formsAsTree = formsAsTree;
  };

  setInboxesAsTree = (inboxesAsTree: boolean): void => {
    this.inboxesAsTree = inboxesAsTree;
  };

  setCurrentOU = (ou: string) => (this.currentOU = ou);

  setUserOUs = (ous: OrganizationalUnitInfo[]) => (this.userOUs = ous);

  setInboxesAsAlphaNumericSort = (inboxesAsAlphaNumericSort: boolean): void => {
    this.inboxesAsAlphaNumericSort = inboxesAsAlphaNumericSort;
  };

  setDeclarationsAsTree = (declarationsAsTree: boolean): void => {
    this.declarationsAsTree = declarationsAsTree;
  };

  async getApiInformation() {
    try {
      const result = await this.clientHTTP.get<AppInformationResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(API_GET_API_INFORMATION),
        {
          withCredentials: true,
        },
      );

      return result.data.information;
    } catch (error) {
      if (QCErrorHTTP.isHttpError<AxiosError<AppInformationResponse>>(error)) {
        error.message = `Erreur sur la requête getAPIInformation: ErrorCode: ${error.response?.data.errorCode} - Message: ${error.response?.data.message}`;
      }
      errorHandler(UserSettingsStore.TAG, error, 'getApiInformation');
    }
  }
}
export default UserSettingsStore;
