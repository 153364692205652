import React from 'react';
import { Button, FormControlLabel, Paper, Tooltip, Typography, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Box } from '@mui/system';
import { DeclarationsToolBarProps } from './types';
import { DividerStyle, MainBoxStyle } from './styles';
import { useData } from './hooks';
import { DeclarationsToolBarName } from './const';
import { hideOnMobile } from '20.formLib/sharedStyles';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';
import { useIsMobile } from '80.quickConnect.Core/hooks';

// eslint-disable-next-line no-empty-pattern
const DeclarationsToolBar = ({}: DeclarationsToolBarProps) => {
  const { t } = useTranslation(['declaration', 'common']);
  const {
    applyFilter,
    clearFilter,
    filterInputValue,
    setFilterInputValue,
    filter,
    handleExpandClick,
    isAllExpanded,
    declarationsTreeNodeKeys,
    numberDisplayed,
    totalNumber,
    declarationsAsTree,
    setDeclarationsAsTree,
    navigateToOldDeclaration,
  } = useData();
  const isMobile = useIsMobile();

  return (
    <Paper elevation={0} data-cy={DeclarationsToolBarName} sx={MainBoxStyle(isMobile)}>
      <Box sx={{ width: `${isMobile && '100%'}`, mb: `${isMobile && '5px'}` }}>
        <SearchBar
          toolTip={t('search_bar').toString()}
          filterInputValue={filterInputValue}
          setFilter={setFilterInputValue}
          filter={filter}
          applyFilter={() => {}}
          clearFilter={clearFilter}
          setSearchIconToRight={false}
        />
      </Box>
      {/* En mode mobile on n'affiche pas le bouton */}
      <Box sx={{ ...hideOnMobile, ...DividerStyle }}></Box>
      <Box>
        <Tooltip title={t('qcapp_common_switch_view_mode', { ns: 'common' }).toString()}>
          <FormControlLabel
            sx={hideOnMobile}
            control={
              <Switch
                checked={declarationsAsTree}
                onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                  setDeclarationsAsTree(checked)
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={t('qcapp_declaration_tree_view').toString()}
          />
        </Tooltip>
      </Box>

      <Box>
        <Tooltip
          disableHoverListener={!declarationsAsTree}
          title={
            isAllExpanded
              ? t('qcapp_common_expand_all', { ns: 'common' }).toString()
              : t('qcapp_common_collapse_all', { ns: 'common' }).toString()
          }
        >
          <span>
            <Button
              sx={{ ...hideOnMobile, visibility: declarationsAsTree ? 'visible' : 'hidden' }}
              disabled={declarationsTreeNodeKeys.length === 0}
              variant="contained"
              startIcon={isAllExpanded ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
              onClick={handleExpandClick}
            >
              {isAllExpanded
                ? t('qcapp_common_expand_all', { ns: 'common' })
                : t('qcapp_common_collapse_all', { ns: 'common' })}
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box sx={{ ...hideOnMobile, ...DividerStyle }}></Box>
      <Box sx={{ margin: `${isMobile && '0px auto'}` }}>
        <Tooltip title={t('qcapp_histo_edited_search_old').toString()}>
          <Button variant="contained" onClick={navigateToOldDeclaration}>
            {t('qcapp_histo_edited_search_old')}
          </Button>
        </Tooltip>
      </Box>
    </Paper>
  );
};
export default observer(DeclarationsToolBar);
