import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { GenericIdNameAttribute, Member, WSActType } from '../typesWorkSite';
import { useTopBarWorkSiteContextSetter, useWorkSiteContext } from '../WorkSiteSummaryWrapper';
import ChooseNewOrExistingAddDialog from '../SubPartsAndUsers/SubParts/ChooseNewOrExistingAddDialog';
import { filterFromString } from '../../../shared/utils/utils';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

const WorkSiteTeamList: FunctionComponent = () => {
  const { t } = useTranslation('moduleWorkSite');
  const { workSite, setWorkSite } = useWorkSiteContext();
  const setRightElementTopBar = useTopBarWorkSiteContextSetter();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [members, setMembers] = useState(workSite.members);

  useEffectOnce(() => {
    setRightElementTopBar(
      <Stack direction="row" alignItems={'center'} justifyContent={'end'} gap={2}>
        <TextField
          onChange={(e) => setMembers(filterFromString(e.target.value, workSite.members || []))}
          size={'small'}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            },
          }}
        />
        <Button onClick={() => setIsDialogOpen(true)} variant={'contained'} startIcon={<PersonAddAlt1Icon />}>
          {t('work_site_members_add_member')}
        </Button>
      </Stack>,
    );
  });

  function onNewMemberAdd<T extends GenericIdNameAttribute>(listToAdd: Array<T>) {
    setWorkSite({ type: WSActType.ADDMEMBERS, value: listToAdd as unknown as Array<Member> });
  }

  return (
    <Box data-cy="TeamList">
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <ChooseNewOrExistingAddDialog
          onClose={() => setIsDialogOpen(false)}
          onAdd={onNewMemberAdd}
          baseData={workSite.members || []}
          labels={{
            addEntity: t('work_site_members_dialog_add_or_create_add'),
            createEntity: t('work_site_members_dialog_add_or_create_create'),
            existingEntityTitle: t('work_site_members_dialog_add_title'),
            newEntityTitle: t('work_site_members_dialog_create_title'),
          }}
        />
      </Dialog>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell width={'100%'}>{'Nom du collaborateur'}</TableCell>
            <TableCell>{'Login'}</TableCell>
            <TableCell>{/*Delete column --> no label*/}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members?.map((member) => {
            return (
              <TableRow key={member.id}>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.login}</TableCell>
                <TableCell>
                  <IconButton onClick={() => setWorkSite({ type: WSActType.REMOVEMEMBER, value: member.id })}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default WorkSiteTeamList;
