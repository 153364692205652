import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, MenuItem, OutlinedInput, Select, CircularProgress } from '@mui/material';
import useData from './hooks';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FlexRow, FieldMainBox } from '50.quickConnect.Fields/styles';
import { ComboDesc } from '90.quickConnect.Models/models';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';
import RadioListQcField from '50.quickConnect.Fields/FieldsTypes/Lists/RadioListQcField';

const ComboQcField = (commonProps: ValueFieldsTypesProps) => {
  const { field, updateDeclaration, labelSpan, setSelectedIndex, isReadOnly, labelClass, openByImagesGroupField } =
    commonProps;

  const {
    t,
    localValue,
    updateLocalState,
    choices,
    nameWithId,
    toolTip,
    label,
    id,
    setSearchValue,
    searchValue,
    handleClear,
  } = useData(field, updateDeclaration);
  const { isPopup, fullPathId } = field as ComboDesc;
  const { breakpoints } = useTheme();

  if (openByImagesGroupField) return <RadioListQcField {...commonProps} />;

  return (
    <Box
      className={labelClass}
      id={`scrollToAnchor-${fullPathId}`}
      data-cy={nameWithId}
      sx={FieldMainBox(breakpoints)}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      <Box sx={FlexRow}>
        {labelSpan}
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>

      <Select
        defaultValue=""
        MenuProps={{ autoFocus: false }}
        labelId={`${nameWithId}-label`}
        id={`${nameWithId}-select`}
        data-cy={`${nameWithId}-select`}
        value={choices && localValue ? localValue : ''}
        onChange={updateLocalState}
        readOnly={isReadOnly}
        disabled={isReadOnly}
        inputProps={{
          readOnly: isReadOnly,
          disabled: isReadOnly,
          'aria-label': 'Without label',
        }}
        displayEmpty={true}
      >
        {isPopup && (
          <SearchBar
            toolTip={t('search_bar').toString()}
            filterInputValue={searchValue}
            setFilter={setSearchValue}
            filter={searchValue}
            applyFilter={() => {}}
            clearFilter={handleClear}
            setSearchIconToRight={false}
          />
        )}

        <MenuItem data-cy={`${nameWithId}-${'value-vide'}`} value={''} style={{ height: '40px' }}>
          {''}
        </MenuItem>
        {choices ? (
          choices.map(({ label: choiceLabel, value }, index) => (
            <MenuItem
              data-cy={`${nameWithId}-${value}`}
              key={`${field.fullPathId}-${value}-${label}-${index}`}
              // label¤valeur pour traiter le cas de doublons dans les listes, mais seule la valeur est stockée
              value={`${choiceLabel}¤${value}`}
            >
              {choiceLabel}
            </MenuItem>
          ))
        ) : (
          <CircularProgress />
        )}
      </Select>
    </Box>
  );
};
export default observer(ComboQcField);
