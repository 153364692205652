import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useTranslation } from 'react-i18next';
import { useTopBarWorkSiteContextSetter, useWorkSiteContext } from '../../WorkSiteSummaryWrapper';
import { GenericIdNameAttribute, WorkSiteProps, WSActType } from '../../typesWorkSite';
import ChooseNewOrExistingAddDialog from '../SubParts/ChooseNewOrExistingAddDialog';
import { filterFromString } from '../../../../shared/utils/utils';
import useIdNameList from './hookWorkSiteUsers';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(isSelected: boolean, theme: Theme) {
  return {
    fontWeight: isSelected ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  };
}

const WorkSiteUsers: FunctionComponent = () => {
  const { t } = useTranslation('moduleWorkSite');
  const { workSite, setWorkSite } = useWorkSiteContext();
  const theme = useTheme();
  const roleList = useIdNameList({ url: 'role', worksite: workSite });
  const packList = useIdNameList({ url: 'packs', worksite: workSite });
  const setRightElementTopBar = useTopBarWorkSiteContextSetter();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<WorkSiteProps['users']>(workSite.users);

  useEffectOnce(() => {
    setRightElementTopBar(
      <Stack direction="row" alignItems={'center'} justifyContent={'end'} gap={2}>
        <TextField
          onChange={(e) => setUsers(filterFromString(e.target.value, workSite.users || []))}
          size={'small'}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            },
          }}
        />
        <Button onClick={() => setIsDialogOpen(true)} variant={'contained'} startIcon={<PersonAddAlt1Icon />}>
          {t('work_site_users_add_user')}
        </Button>
      </Stack>,
    );
  });

  const handleChange = (
    event: SelectChangeEvent<GenericIdNameAttribute[]>,
    userId: string,
    type: WSActType.SELECTUSERROLE | WSActType.SELECTUSERPACK,
  ) => {
    const {
      target: { value },
    } = event;
    setWorkSite({ type: type, value: { userId: userId, id: value[value.length - 1] as string } });
  };

  function getRoleSelector(
    baseList: Array<GenericIdNameAttribute>,
    selectedList: Array<GenericIdNameAttribute['id']>,
    userId: string,
    type: WSActType.SELECTUSERROLE | WSActType.SELECTUSERPACK,
    inputLabel: string,
  ) {
    return (
      <FormControl sx={{ m: '0.1rem', width: 400 }}>
        <InputLabel id="demo-multiple-chip-label">{inputLabel}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          size="small"
          multiple
          value={baseList}
          onChange={(e) => handleChange(e, userId, type)}
          input={<OutlinedInput id="select-multiple-chip" label={inputLabel} />}
          renderValue={(elements: Array<GenericIdNameAttribute>) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {elements
                .filter((elem) => selectedList.includes(elem.id))
                .map((elem) => (
                  <Chip key={elem.id} label={elem.name} />
                ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {baseList.map((elem) => {
            const isSelected = selectedList.includes(elem.id);
            return (
              <MenuItem key={elem.id} value={elem.id} style={getStyles(isSelected, theme)}>
                <Checkbox checked={isSelected} />
                <ListItemText primary={elem.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  function addUser(listToAdd: Array<GenericIdNameAttribute>) {
    setWorkSite({
      type: WSActType.ADDUSERS,
      value: listToAdd.map((user) => ({ ...user, packs: [], roles: [] })),
    });
  }

  return (
    <Box data-cy="WorkSiteUsers">
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <ChooseNewOrExistingAddDialog
          onClose={() => setIsDialogOpen(false)}
          onAdd={addUser}
          baseData={workSite.users || []}
          labels={{
            addEntity: t('work_site_users_dialog_add_or_create_add'),
            createEntity: t('work_site_users_dialog_add_or_create_create'),
            existingEntityTitle: t('work_site_users_dialog_add_title'),
            newEntityTitle: t('work_site_users_dialog_create_title'),
          }}
        />
      </Dialog>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell width={'100%'}>{t('work_site_users_user')}</TableCell>
            <TableCell>{t('work_site_users_role')}</TableCell>
            <TableCell>{t('work_site_users_activity_pack')}</TableCell>
            <TableCell>{/*Delete column --> no label*/}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => {
            return (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>
                  {getRoleSelector(roleList, user.roles, user.id, WSActType.SELECTUSERROLE, t('work_site_users_role'))}
                </TableCell>
                <TableCell>
                  {getRoleSelector(
                    packList,
                    user.packs,
                    user.id,
                    WSActType.SELECTUSERPACK,
                    t('work_site_users_activity_pack'),
                  )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => setWorkSite({ type: WSActType.REMOVEUSER, value: user.id })}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default WorkSiteUsers;
