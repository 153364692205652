import React, { CSSProperties, FunctionComponent } from 'react';
import { Button, Grid2, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import WorkSiteTopBar from '../../../shared/TopBar/WorkSiteTopBar';
import { NEW_WORKSITE_DEFAULT_ID } from '../../Summary/typesWorkSite';
import useWorkSiteListData from './hookWorkSiteList';
import WorkSiteCard from './WorkSiteCard';
import { WORK_SITE_SUMMARY } from '10.quickConnect.app/routes/paths';

const containerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexGrow: 1,
  flexWrap: 'wrap',
  width: '100%',
  height: '100%',
  padding: '2rem',
};

const WorkSiteList: FunctionComponent = () => {
  const { workSiteList } = useWorkSiteListData();
  const { t } = useTranslation('moduleWorkSite');
  const navigate = useNavigate();

  const goToNewWorkSitePage = () => {
    navigate(WORK_SITE_SUMMARY.replace(':workSiteId', NEW_WORKSITE_DEFAULT_ID));
  };

  return (
    <>
      <WorkSiteTopBar
        leftElement={
          <Stack>
            <Typography fontWeight={'bold'} sx={{ textTransform: 'uppercase' }}>
              {t('work_site_list_title_message')}
            </Typography>
            <Typography>{t('work_site_list_sub_message')}</Typography>
          </Stack>
        }
        rightElement={
          <Button variant={'contained'} startIcon={<AddIcon />} onClick={goToNewWorkSitePage}>
            {t('work_site_list_new_worksite')}
          </Button>
        }
      />
      <Grid2 container style={containerStyle} spacing={'2rem'} rowSpacing={0}>
        {workSiteList.map((site) => {
          return (
            <WorkSiteCard
              key={site.id}
              id={site.id}
              icon={site.icon}
              name={site.name}
              published={site.published}
              status={site.status}
            />
          );
        })}
      </Grid2>
    </>
  );
};

export default WorkSiteList;
