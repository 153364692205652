import React, { ChangeEvent, FunctionComponent, useRef } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { AddNewUserDialogProps, Entity } from './ChooseNewOrExistingAddDialog';

const CreateNewEntityDialog: FunctionComponent<AddNewUserDialogProps> = (props: AddNewUserDialogProps) => {
  // TODO KTY connect all inputs
  const { t } = useTranslation('moduleWorkSite');
  const newEntity = useRef<Entity>({});

  function addEntity() {
    // TODO KTY faire un call API pour demander au serveur de crée l'entité
    props.onAdd([]);
  }

  function updateValue<K extends keyof Entity>(key: K, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    newEntity.current[key] = e.target.value;
  }

  return (
    <>
      <DialogTitle data-cy="AddNewUserDialog">
        <Stack direction={'row'} justifyContent={'space-between'}>
          {props.labels.newEntityTitle}
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextField
              label={t('work_site_entity_dialog_create_last_name')}
              fullWidth
              onChange={(e) => updateValue('lastName', e)}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              label={t('work_site_entity_dialog_create_first_name')}
              fullWidth
              onChange={(e) => updateValue('firstName', e)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={t('work_site_entity_dialog_create_email')}
              fullWidth
              onChange={(e) => updateValue('email', e)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={t('work_site_entity_dialog_create_login')}
              fullWidth
              onChange={(e) => updateValue('login', e)}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              label={t('work_site_entity_dialog_create_phone')}
              fullWidth
              onChange={(e) => updateValue('phone', e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} width={'100%'} spacing={2}>
          <Button fullWidth onClick={props.onClose} variant={'outlined'}>
            {t('work_site_action_cancel')}
          </Button>
          <Button fullWidth variant={'contained'} onClick={addEntity}>
            {t('work_site_action_add')}
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default CreateNewEntityDialog;
