import { useState } from 'react';
import { toJS } from 'mobx';
import { UseGetImagesSources } from '../types';
import { useStore } from '30.quickConnect.Stores';
import { useImagesGroupContext } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/contexts/imagesGroupContext';
import { errorHandler } from '80.quickConnect.Core/helpers';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

const TAG =
  '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/ImageGroupItem/hooks/useGetImagesGroupSources.ts';

export function useGetImagesSources(endPoint: string): UseGetImagesSources {
  // on récupère les infos des contextes
  const {
    imagesStateInfos: { imagesBankId },
  } = useImagesGroupContext();

  const [srcSet, setSrcSet] = useState<string>('');

  const {
    // LoginStore: { urlToUse },
    DeclarationStore: { urlToUseForImagesBank },
  } = useStore();

  useEffectOnce(() => {
    (async () => {
      try {
        // const baseRemoteImageUrl = toJS(urlToUse)?.replace('https://', 'https://imagesgroup.').replace('/api/', '');
        let src = '';

        if (urlToUseForImagesBank !== undefined) {
          const urls = urlToUseForImagesBank?.split('?', 2);
          src = `${urls[0]}/${imagesBankId}/img/original/${endPoint}?${urls[1]}`;
        }

        setSrcSet(src);
      } catch (error) {
        errorHandler(TAG, error, 'useEffectOnce');
      }
    })();
    // Appel au service DeclarationStore pour récupérer la source de l'image.
  });

  return {
    srcSet,
  };
}
