import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { QCActionValue, SearchedList } from './types';
import { useStore } from '30.quickConnect.Stores';
import {
  AllFieldValueTypes,
  Choice,
  DeclarationContext,
  DialogDesc,
  FieldDesc,
  QcActionDesc,
} from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { FieldType } from '90.quickConnect.Models/enums';
import { useDeclarationContext } from '10.quickConnect.app/components/domain/Declaration/context/declarationContext';
import { getActionParamFromDeclaration } from '50.quickConnect.Fields/FieldsTypes/Others/QcAction/utils';

const useDataAction = (
  actionField: QcActionDesc & DialogDesc,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
  context: DeclarationContext,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  field: FieldDesc,
  open: string | undefined,
  flattenFields: FieldDesc[],
  handleSubmit: (_event: React.SyntheticEvent<Element, Event>) => void,
  handleCancel: (_event: React.SyntheticEvent<Element, Event>) => void,
) => {
  const {
    DeclarationStore: { actionSearchData },
  } = useStore();
  const { fullPathId, value } = field;
  const [localValueForCheckBox, setLocalValueForCheckBox] = useState<Choice[]>([]);
  const [serachedList, setSearchedList] = useState<SearchedList>();
  const [pagination, setPagination] = useState<{ [key: number]: string }>();
  const [actionToken, setActionToken] = useState<string>('');
  const [localValue, setLocalValue] = useState<string>((value as Choice)?.value ?? '');
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [currentElementToDelete, setCurrentElementToDelete] = useState<string>();
  const [compteur, setCompteur] = useState<number>(0);
  const [validatedLocalValueForCheckBox, setValidatedLocalValueForCheckBox] = useState<Choice[]>([]); // Pour les sélections multiples
  const [validatedLocalValue, setValidatedLocalValue] = useState<string>(''); // Pour les sélections simples

  const declaration = useDeclarationContext();

  const initialSearch = useCallback(
    async () => {
      const body = {
        actionName: actionField.actionName,
        actionParam: {
          values: getActionParamFromDeclaration(declaration, fullPathId),
        },
        actionToken: '',
        formId: context.formId,
      };
      const res = await actionSearchData(body);
      if (res) {
        setActionToken(res.data.actionToken);
        setSearchedList(res.data);
        const nextValue: QCActionValue[] = [
          {
            fieldType: FieldType.CheckBoxList,
            id: actionField.id,
            label: actionField.label ?? '',
            value: res.data.resultList,
          },
        ];
      }
    },
    // We don't want update on useCallback references so we don't subscribe to declaration which will update after each call
    // because the function is call on init, we shouldn't have dependencies delta
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actionField.actionName,
      actionField.id,
      actionField.label,
      context.formId,
      fullPathId,
      actionSearchData,
      // KEEP COMMENTED otherwise it will become an infinite loop
      // updateDeclaration,
      // declaration,
    ],
  );

  useEffect(() => {
    if (actionField?.callOnStart && open && open === fullPathId) {
      initialSearch();
    }
  }, [
    actionField.actionName,
    actionField?.callOnStart,
    actionSearchData,
    context.formId,
    open,
    fullPathId,
    actionField.isPopup,
    initialSearch,
    actionField.value,
  ]);

  const preselectedChoices = useMemo(() => {
    if (!actionField.value) return [];
    const values = (actionField.value as QCActionValue[])?.[0]?.value;
    return Array.isArray(values) ? values : [values];
  }, [actionField.value]);

  useEffect(() => {
    if (open && open === fullPathId && preselectedChoices.length > 0) {
      if (actionField.isMultiSelection) {
        setLocalValueForCheckBox(preselectedChoices);
      } else {
        setLocalValue(preselectedChoices[0]?.value ?? '');
      }
    }
  }, [open, fullPathId, preselectedChoices, actionField.isMultiSelection]);

  const updateGlobalState = useCallback(() => {
    const selectedChoice = serachedList?.resultList.find((c) => c.value === localValue);
    let data;

    if (actionField.isMultiSelection) {
      data = [
        {
          fieldType: FieldType.CheckBoxList,
          id: actionField.id,
          label: actionField.label,
          value: localValueForCheckBox,
        },
      ];
    } else if (selectedChoice) {
      data = [
        { fieldType: FieldType.RadioList, id: actionField.id, label: actionField.label, value: [selectedChoice] },
      ];
    }
    if (isDelete) {
      const selectedValues = (actionField.value as QCActionValue[])?.[0]?.value;
      const normalizedSelectedValues =
        selectedValues == null ? [] : Array.isArray(selectedValues) ? selectedValues : [selectedValues];
      const x = normalizedSelectedValues.filter((v: Choice) => currentElementToDelete !== v.value);
      if (x.length === 0) {
        data = undefined;
      } else {
        data = [{ ...(actionField?.value as QCActionValue[])[0], value: x }];
      }
      setLocalValueForCheckBox((prev) => prev.filter((choice) => choice.value !== currentElementToDelete));
      setLocalValue('');
    }
    updateDeclaration(fullPathId, data as any);
    setIsDelete(false);
  }, [
    serachedList?.resultList,
    actionField.isMultiSelection,
    actionField.id,
    actionField.label,
    actionField?.value,
    isDelete,
    updateDeclaration,
    fullPathId,
    localValue,
    localValueForCheckBox,
    currentElementToDelete,
  ]);

  const canSearch = useMemo(
    () => (actionField.items ? !actionField.items.some((f) => f.errors && f.errors?.length > 0) : false),
    [actionField.items],
  );

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const updateCheckBoxLocalState = useCallback(
    (choice: Choice) => () => {
      const newChecked = [...localValueForCheckBox];
      const currentIndex = localValueForCheckBox.findIndex((c) => c.value === choice.value);
      if (currentIndex === -1) {
        newChecked.push(choice);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setLocalValueForCheckBox(newChecked);
    },
    [localValueForCheckBox],
  );
  const updateLocalState = useCallback((_event: React.ChangeEvent<HTMLInputElement>, newValue: string | Choice) => {
    setLocalValue(newValue as string);
  }, []);

  const resetFields = useCallback(() => {
    // Réinitialiser les valeurs des items
    if (actionField.items) {
      actionField.items.forEach((item) => {
        if ('defaultValue' in item && item.defaultValue !== undefined && item.defaultValue !== '') {
          return;
        }
        item.value = null;
      });
    }
    setSearchedList(undefined);
    setPagination(undefined);
  }, [actionField.items]);

  const handleSubmitWithReset = useCallback(
    (_event: React.SyntheticEvent<Element, Event>) => {
      updateGlobalState();
      handleSubmit(_event);
      setValidatedLocalValueForCheckBox([...localValueForCheckBox]); // Mettre à jour l'état validé pour les sélections multiples
      setValidatedLocalValue(localValue); // Mettre à jour pour les sélections simples
      resetFields(); // Réinitialisation des champs
    },
    [handleSubmit, localValue, localValueForCheckBox, resetFields, updateGlobalState],
  );

  const handleCancelWithReset = useCallback(
    (_event: React.SyntheticEvent<Element, Event>) => {
      handleCancel(_event);
      setLocalValueForCheckBox([...validatedLocalValueForCheckBox]); // Réinitialiser à la dernière valeur validée
      setLocalValue(validatedLocalValue); // Réinitialiser à la dernière valeur validée
      resetFields(); // Réinitialisation des champs
    },
    [handleCancel, resetFields, validatedLocalValue, validatedLocalValueForCheckBox],
  );

  const handleSearch = useCallback(
    async (actionsToken?: string) => {
      const findValueToSearch = actionField.items.filter((it) => it.value);
      if (findValueToSearch) {
        const findVal = findValueToSearch.map((v) => ({
          id: v.id,
          fieldType: v.fieldType,
          label: v.label,
          value: v.value,
        }));
        const fetch = async () => {
          const body = {
            actionName: actionField.actionName,
            actionParam: {
              values: findVal,
            },
            actionToken: actionsToken ?? '',
            formId: context.formId,
          };
          const res = await actionSearchData(body);
          if (res) {
            setActionToken(res.data.actionToken);
            setSearchedList(res.data);
          }
        };
        await fetch();
      }
    },
    [actionField.actionName, actionField.items, actionSearchData, context],
  );
  const handleNext = useCallback(async () => {
    const nextIndex = compteur + 1;
    setPagination({ ...pagination, [nextIndex]: actionToken });
    await handleSearch(actionToken);
    setCompteur(() => compteur + 1);
  }, [actionToken, compteur, handleSearch, pagination]);

  const handlePrevious = useCallback(async () => {
    const previousIndex = compteur - 1;

    await handleSearch(pagination![previousIndex]);
    setCompteur(() => compteur - 1);
  }, [compteur, handleSearch, pagination]);

  const handleDelete = useCallback(
    (currentElem: string) => {
      setLocalValue('');
      setIsDelete(true);
      setCurrentElementToDelete(currentElem);
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState],
  );
  return {
    handleSearch,
    serachedList,
    handleNext,
    handlePrevious,
    updateLocalState,
    localValue,
    compteur,
    canSearch,
    handleDelete,
    updateCheckBoxLocalState,
    localValueForCheckBox,
    initialSearch,
    handleSubmitWithReset,
    handleCancelWithReset,
  };
};
export default useDataAction;
