import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import useData from './hooks';
import { FieldsProps } from './types';
import { FieldsName } from './const';
import { C2SImportant, MainBox } from './styles';
import FieldRenderer from './FieldRenderer';
import { FieldDesc } from '90.quickConnect.Models/models';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import ConfirmDialog from '10.quickConnect.app/components/shared/LayOut/ConfirmDialog';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';

const Fields = ({
  fieldsDescriptions,
  isReadOnly,
  flattenFields,
  updateDeclaration,
  setDeclaration,
  context,
  updateFieldErrors,
  updateItemsForThisField,
}: FieldsProps) => {
  const {
    selectedIndex,
    setSelectedIndex,
    isDrawerOpened,
    hasConsentsAsked,
    fieldsToAsked,
    onAcceptConsent,
    onDeclineConsent,
  } = useData(flattenFields, context);
  const { t } = useTranslation('declaration');
  const { breakpoints } = useTheme();

  return (
    <Box data-cy={FieldsName} sx={MainBox(breakpoints, isDrawerOpened)}>
      {fieldsDescriptions.map((item: FieldDesc) =>
        item?.importance ? (
          <Box sx={C2SImportant(item.importance)} key={item.fullPathId}>
            <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
              <FieldRenderer
                key={getFieldKey(item)}
                field={item}
                updateDeclaration={updateDeclaration}
                updateItemsForThisField={updateItemsForThisField}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                isReadOnly={isReadOnly || item.fieldIsReadOnly}
                flattenFields={flattenFields}
                setDeclaration={setDeclaration}
                context={context}
                updateFieldErrors={updateFieldErrors}
              />
            </MandatoryField>
          </Box>
        ) : (
          <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
            <FieldRenderer
              key={getFieldKey(item)}
              field={item}
              updateDeclaration={updateDeclaration}
              updateItemsForThisField={updateItemsForThisField}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              isReadOnly={isReadOnly || item.fieldIsReadOnly}
              flattenFields={flattenFields}
              setDeclaration={setDeclaration}
              context={context}
              updateFieldErrors={updateFieldErrors}
            />
          </MandatoryField>
        ),
      )}
      {hasConsentsAsked && ((context.editedData && context.isDraft) || (!context.editedData && !context.isDraft)) && (
        <ConfirmDialog
          open={hasConsentsAsked}
          close={() => {}}
          actionForLabelOne={onAcceptConsent}
          actionForLabelTwo={onDeclineConsent}
          actionLabelOne={t('qcapp_alert_dialog_ask_user_consent_ok').toString()}
          actionLabelTwo={t('qcapp_alert_dialog_ask_user_consent_cancel').toString()}
          title={t('qcapp_alert_dialog_ask_user_consent_title').toString()}
          subtitle={t('qcapp_alert_dialog_ask_user_consent_fields_message').toString()}
          sendingData={false}
        >
          <Box p={3} sx={{ display: 'block', justifyContent: 'center' }}>
            {fieldsToAsked.map((i) => (
              <Box key={i.fieldFullId}>
                <Typography>- {i.labelField?.replace(/(<([^>]+)>)/gi, '')}</Typography>
              </Box>
            ))}
          </Box>
        </ConfirmDialog>
      )}
    </Box>
  );
};
export default observer(Fields);
