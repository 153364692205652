import { useLocation, useNavigate, useParams } from 'react-router';
import { Dispatch, Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import { ActWS, NEW_WORKSITE_DEFAULT_ID, WorkSiteProps, workSiteReducer, WSActType } from './typesWorkSite';
import { WORK_SITE_SELECT, WORK_SITE_SUMMARY } from '10.quickConnect.app/routes/paths';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { useStore } from '30.quickConnect.Stores';

export type WorkSiteSummaryProps = {
  workSite: WorkSiteProps;
  setWorkSite: Dispatch<ActWS>;
};

export type WorkSiteSummaryWrapperProps = {
  backPage: () => void;
  displayUnsavedDialog: boolean;
  confirmLossOfData: () => void;
  cancelLeavingWithoutSaving: () => void;
};

const useWorkSiteSummaryWrapperData = (): WorkSiteSummaryProps & WorkSiteSummaryWrapperProps => {
  const { WorkSiteStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { workSiteId } = useParams();
  const [workSite, setWorkSite] = useReducer<Reducer<WorkSiteProps, ActWS>>(workSiteReducer, {
    id: workSiteId || NEW_WORKSITE_DEFAULT_ID,
    hasChanged: false,
  });
  const [displayUnsavedDialog, setDisplayUnsavedDialog] = useState<boolean>(false);

  useEffectOnce(() => {
    console.log('TODO KTY call the chantier data !');
    //TODO KTY get worksite Id from url (remove "none")
    if (!workSiteId) {
      //TODO KTY afficher une erreur
      return;
    }
    if (workSiteId === NEW_WORKSITE_DEFAULT_ID) {
      // We are creating a new worksite here
      return;
    }
    setWorkSite({ type: WSActType.RESET });
    WorkSiteStore.getWorkSite(workSiteId)
      .then((res) => {
        const responseWorksite = res;
        if (!responseWorksite) {
          return;
        }
        if (responseWorksite.name !== undefined) {
          //TODO KTY delete, only for mock data
          if (process.env.REACT_APP_QC_WORK_SITE_MOCK_DATA === 'true') {
            setWorkSite({ type: WSActType.NAME, value: responseWorksite.name });
          } else {
            setWorkSite({ type: WSActType.NAME, value: responseWorksite.name });
          }
        }
        if (responseWorksite.agency !== undefined) {
          setWorkSite({ type: WSActType.AGENCY, value: responseWorksite.agency });
        }
        if (responseWorksite.postalCode !== undefined) {
          setWorkSite({ type: WSActType.POSTCODE, value: responseWorksite.postalCode });
        }
        if (responseWorksite.standardPacks !== undefined) {
          //TODO KTY parse the real data to Packs
          setWorkSite({ type: WSActType.STANDARDPACKS, value: responseWorksite.standardPacks });
        }
        if (responseWorksite.otherPacks !== undefined) {
          //TODO KTY parse the real data to Packs
          setWorkSite({ type: WSActType.OTHERPACKS, value: responseWorksite.otherPacks });
        }
        if (responseWorksite.reports !== undefined) {
          //TODO KTY parse real data to Reports
          setWorkSite({ type: WSActType.REPORTS, value: responseWorksite.reports });
        }
        if (responseWorksite.users !== undefined) {
          //TODO KTY parse real data to Users
          setWorkSite({ type: WSActType.ADDUSERS, value: responseWorksite.users });
        }
        if (responseWorksite.members !== undefined) {
          //TODO KTY parse real data to Members
          setWorkSite({ type: WSActType.ADDMEMBERS, value: responseWorksite.members });
        }
      })
      .finally(() => {
        setWorkSite({ type: WSActType.HASCHANGED, value: false });
      });
  });

  function displayUnsavedChangesPopup() {
    setDisplayUnsavedDialog(true);
  }

  function cancelLeavingWithoutSaving() {
    setDisplayUnsavedDialog(false);
  }

  // Prevent user from refreshing when there are unsaved changes
  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (workSite.hasChanged) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [workSite.hasChanged]);

  const backPage = useCallback(() => {
    if (location.pathname.replace(workSite.id || '', '') === WORK_SITE_SUMMARY.replace(':workSiteId', '')) {
      if (workSite.hasChanged) {
        displayUnsavedChangesPopup();
        return;
      }
      navigate(WORK_SITE_SELECT);
      return;
    }
    navigate('.');
  }, [location.pathname, navigate, workSite.hasChanged, workSite.id]);

  function confirmLossOfData() {
    setDisplayUnsavedDialog(false);
    setWorkSite({ type: WSActType.HASCHANGED, value: false });
    navigate(WORK_SITE_SELECT);
    return;
  }

  return {
    workSite,
    setWorkSite,
    backPage,
    displayUnsavedDialog,
    confirmLossOfData,
    cancelLeavingWithoutSaving,
  };
};

export default useWorkSiteSummaryWrapperData;
