import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { SynchronizeEquipmentReturnType } from '90.quickConnect.Models/enums';
import useWindowSize from '80.quickConnect.Core/hooks/useWindowSize';
import { EQUIPMENT } from '10.quickConnect.app/routes/paths';
import { EntityData } from '90.quickConnect.Models/models';

const useData = (): UseDataProps => {
  // Tag
  const tag = '10.quickConnect.app/components/domain/QRCodeScan/hooks.ts';

  // On récupère le store
  const {
    EquipmentsStore: { synchronizeEquipmentsAsync },
  } = useStore();

  // On set le state
  const { t: tAxios } = useTranslation('axios');
  const navigate = useNavigate();
  const [ratio, setRatio] = useState(1);
  const { width, height } = useWindowSize();
  const [code, setCode] = useState('');

  // On ajoute des hooks

  const handleResponse = useCallback(
    (newData: EntityData | undefined): void => {
      if (newData) {
        navigate(EQUIPMENT.replace(':schemaId', newData.entitySchemaId).replace(':entityId', newData.id));
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (height && width) {
      if (width > height) {
        setRatio((height - 200) / width);
      } else {
        setRatio(1);
      }
    }
  }, [height, width]);

  const searchEquipment = (): void => {
    if (code) {
      //Ajout du tag "##" au code d'équipement pour le différentier côté API du QRcode
      synchronizeEquipmentsAsync(`##${code}`, '', '', SynchronizeEquipmentReturnType.All, undefined, tAxios).then(
        (newData) => handleResponse(newData),
      );
    }
  };

  return {
    handleScan: handleResponse,
    width,
    ratio,
    code,
    setCode,
    searchEquipment,
  };
};

export default useData;
