import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import frLocale from 'date-fns/locale/fr';
import deLocale from 'date-fns/locale/de';
import nlLocale from 'date-fns/locale/nl';
import enLocale from 'date-fns/locale/en-US';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { TimeDesc, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { Mandatory } from '90.quickConnect.Models/enums';
import { isDate } from '80.quickConnect.Core/helpers/common';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';

const useData = (
  timeDesc: TimeDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, value, mandatory } = timeDesc;
  const [localValue, setLocalValue] = useState<Date | string | null>(DateTimeExtension.isDate(value) ? value : null);
  const [error, setError] = useState<boolean>(false);

  const {
    navigator: { language },
  } = window;

  const twoLetteLanguage = useMemo(() => {
    return language.substring(0, 2).toLocaleLowerCase();
  }, [language]);

  const locale = useMemo(() => {
    switch (twoLetteLanguage) {
      case 'fr':
        return frLocale;
      case 'de':
        return deLocale;
      case 'ln':
        return nlLocale;
      case 'en':
      default:
        return enLocale;
    }
  }, [twoLetteLanguage]);

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    if (localValue === '' || localValue == null) {
      updateDeclaration(fullPathId, '');
    } else {
      updateDeclaration(fullPathId, localValue);
    }
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const onChange = useCallback(
    (date: Date | null, inputValue?: string) => {
      const timeRegex = /^[0-2][0-9]:[0-5][0-9]($| [A,P]M$)/;
      // inputValue is undefined when selecting interactively
      if (inputValue === undefined || inputValue?.match(timeRegex)) {
        setLocalValue(date ?? '');
        if (date && !isNaN(date.getTime())) {
          debouncedUpdateGlobalState();
        }
      }
    },
    [debouncedUpdateGlobalState],
  );

  const onAccept = useCallback(
    (date: Date | null) => {
      setLocalValue(date ?? null);
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState],
  );

  useEffect(() => {
    setError(mandatory === Mandatory.Required && (value === undefined || value === ''));
  }, [mandatory, value]);

  // UseEffect pour les références...
  useEffect(() => {
    const newLocalValue =
      isDate(value) && !Number.isNaN(value.getTime()) ? DateTimeExtension.parseFromTime1970(value) : null;
    setLocalValue((previousLocalState: string | Date | null) =>
      previousLocalState !== newLocalValue ? newLocalValue ?? null : previousLocalState,
    );
  }, [value]);

  const handleOnChange = useCallback(() => {
    debouncedUpdateGlobalState();
  }, [debouncedUpdateGlobalState]);

  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    updateGlobalState,
    onChange,
    onAccept,
    error,
    locale,
    handleOnChange,
  };
};

export default useData;
