import React, { FunctionComponent } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowForward } from '@mui/icons-material';
import {
  CardActionsStyle,
  CardMediaStyle,
  CardStyle,
  CustomInputLabelStyle,
  CustomTextFieldStyle,
  MainBoxStyle,
  QcLogoStyle,
  TypoAppVersionStyle,
} from './styles';
import { SignInReducerActionsType } from './types';
import useLoginData from './hooks';
import { Arrow, Ball } from './icons';
import { appVersion } from './index';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import { useModuleContextSetter } from '00.app/module/moduleContext';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

const ModuleChoice: FunctionComponent = () => {
  const { t } = useTranslation('login');
  const { setSignInRequest, currentModule, availableModules } = useLoginData(t);

  const isMobile = useIsMobile();
  const theme = useTheme();
  const setModule = useModuleContextSetter();

  useEffectOnce(() => {
    setSignInRequest({
      type: SignInReducerActionsType.CGU,
      value: true,
    });
    if (!availableModules || Object.keys(availableModules).length <= 1) {
      setModule(AppModulesIDs.MODULE_WEB_CLIENT_ID, true);
    }
  });

  return (
    <Box data-cy="ModuleChoice" sx={MainBoxStyle}>
      <Arrow />
      {!isMobile && <Ball />}
      <Card sx={CardStyle}>
        <CardMedia
          component="img"
          alt="france-piste-cyclable-transfrontaliere"
          image="/assets/logo_QC_medium_standard_HD.png"
          style={QcLogoStyle}
          sx={CardMediaStyle}
        />
        <Typography sx={TypoAppVersionStyle(theme.breakpoints)}>{appVersion}</Typography>
        <CardContent>
          {availableModules && Object.keys(availableModules).length > 1 && (
            <Box>
              <CustomInputLabelStyle htmlFor="outlined-adornment-password">
                {t('qcapp_choose_module')}
              </CustomInputLabelStyle>
              <CustomTextFieldStyle variant="outlined" fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  defaultValue={currentModule}
                  value={currentModule}
                  onChange={(select) => {
                    setSignInRequest({
                      type: SignInReducerActionsType.MODULE,
                      value: select.target.value as AppModulesIDs,
                    });
                  }}
                >
                  {Object.keys(availableModules).map((moduleKey) => {
                    const value = availableModules[moduleKey];
                    return (
                      <MenuItem key={value} value={value}>
                        {moduleKey}
                      </MenuItem>
                    );
                  })}
                </Select>
              </CustomTextFieldStyle>
              <CardActions sx={CardActionsStyle(false)}>
                <Button
                  data-cy="login-btn"
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    setModule(currentModule as AppModulesIDs, true);
                  }}
                  endIcon={<ArrowForward />}
                >
                  {t('qcapp_login_bt_connexion')}
                </Button>
              </CardActions>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ModuleChoice;
