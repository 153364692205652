import React, { useId } from 'react';
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DeclarationMenuName } from './const';
import { useData } from './hooks';
import DeclarationsTree from '10.quickConnect.app/components/domain/Home/Declarations/DeclarationsTrees/DeclarationsTree';
import FormsTree from '10.quickConnect.app/components/domain/Home/Forms/FormsTree';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';

const LeftMenu = () => {
  const { t } = useTranslation('common');
  // eslint-disable-next-line no-empty-pattern
  const {
    LeftMenuDraftsStore,
    LeftMenuHistoryStore,
    LeftMenuStore,
    filterInputValue,
    setFilterInputValue,
    filter,
    applyFilter,
    clearFilter,
  } = useData();
  const drafts = useId();
  const historic = useId();
  const forms = useId();
  return (
    <Box
      data-cy={DeclarationMenuName}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <SearchBar
        toolTip={t('leftMenu_searchBar').toString()}
        filterInputValue={filterInputValue}
        setFilter={setFilterInputValue}
        filter={filter}
        applyFilter={applyFilter}
        clearFilter={clearFilter}
        setSearchIconToRight={false}
      />
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-containt-${drafts}`} id={drafts}>
          <Typography>{t('leftMenu_drafts').toString()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DeclarationsTree hideScrollBar store={LeftMenuDraftsStore} cle={t('leftMenu_drafts').toString()} />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-containt-${historic}`} id={historic}>
          <Typography>{t('leftMenu.historic').toString()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DeclarationsTree hideScrollBar store={LeftMenuHistoryStore} cle={t('leftMenu.historic').toString()} />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-containt-${forms}`}>
          <Typography>{t('leftMenu_forms').toString()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormsTree store={LeftMenuStore} hideScrollBar cle={t('leftMenu_forms').toString()} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default observer(LeftMenu);
