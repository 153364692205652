import { action, makeAutoObservable, observable } from 'mobx';
import RootStore from '../index';
import { AbortRequestsStore } from '../interfaces';
import { BaseResponse } from '40.quickConnect.DataAccess/axios/baseResponse';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { errorHandler } from '80.quickConnect.Core/helpers';
import {
  API_WORK_SITE_ALL_AGENCY,
  API_WORK_SITE_ALL_NAMES,
  API_WORK_SITE_BY_ID,
  API_WORK_SITE_LIST,
} from '40.quickConnect.DataAccess/axios/apiRoutes';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import {
  SimpleWorkSite,
  WorkSiteStatus,
} from '11.moduleWorkSite.app/components/domain/Home/WorkSiteList/hookWorkSiteList';
import {
  GenericIdNameAttribute,
  Member,
  PackStatus,
  Report,
  User,
  WorkSiteProps,
} from '11.moduleWorkSite.app/components/domain/Summary/typesWorkSite';

/*
TODO KTY MOCK DATA (ALL!!!!!)
 */

export const mockPackData = [
  { id: 'unId', name: 'Sécurité', status: PackStatus.COMPLETED, nbForm: 0, selected: false },
  { id: 'deuxId', name: 'Suivi Béton', status: PackStatus.SYNCING, nbForm: 0, selected: true },
  { id: 'troisId', name: 'Contrôles qualité', status: PackStatus.TOCOMPLETE, nbForm: 0, selected: false },
  { id: 'quatesId', name: 'Renforcement carbone', status: PackStatus.WAITING, nbForm: 0, selected: true },
  { id: 'cinqId', name: 'suivi des éléments préfabriqués', status: PackStatus.COMPLETED, nbForm: 0, selected: false },
  { id: 'sixId', name: 'tracking et BL dans SAP (€)', status: PackStatus.COMPLETED, nbForm: 0, selected: true },
];
const mockDataReports: Report[] = [
  {
    id: 'unId',
    name: "Rapport 1: J'ai besoins aussi d'un nom de rapport assez long parce que le dev front il doit aussi gérer ça",
    nbMissingReports: 3,
    description:
      "Description d'un formulaire, il faut que j'écrive quelque chose de long pour voir comment l'interface vas réagir. Si je ne fait pas ça les utilisateurs vont pouvoir avoir une description à ralonge qui vas casser l'interface et ça on ne veux pas que ça arrive Je vais donc continuer a écrire des lignes de texte pour enquiquiner le dev front",
    previewPictures: [
      'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image11.png',
      'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image3.png',
      'https://i.pinimg.com/originals/9c/7b/e4/9c7be43979a736a8695361a544630b97.jpg',
    ],
    selected: false,
    forms: {
      Sécurité: [
        { id: 'formId1', name: 'Form1Secu', completed: true },
        { id: 'formId2', name: 'Form2Secu', completed: false },
      ],
      'Suivi Béton': [
        { id: 'formIdB1', name: 'Form1SB', completed: true },
        { id: 'formIdB2', name: 'Form2SB', completed: true },
      ],
      'Contrôles qualité': [
        { id: 'formIdCQ1', name: 'Form1CQ', completed: false },
        { id: 'formIdCQ2', name: 'Form2CQ', completed: false },
      ],
    },
  },
  {
    id: 'deuxId',
    name: 'Rapport 2',
    nbMissingReports: 0,
    description: "Description d'un formulaire",
    previewPictures: [
      'https://placehold.co/1000x400/EEE/31343C',
      'https://placehold.co/1000x1000/EEE/31343C',
      'https://placehold.co/100x1000/EEE/31343C',
      'https://placehold.co/50x50/EEE/31343C',
    ],
    selected: false,
    forms: { Securite: [{ id: 'formId', name: 'Form1', completed: true }] },
  },
  {
    id: 'TroisId',
    name: 'Rapport 3',
    nbMissingReports: 1,
    description: "Description d'un formulaire",
    previewPictures: [],
    selected: false,
    forms: { Securite: [{ id: 'formId', name: 'Form1', completed: false }] },
  },
  {
    id: 'QuatreId',
    name: 'Rapport 4',
    nbMissingReports: 0,
    description: "Description d'un formulaire",
    previewPictures: [],
    selected: false,
    forms: { Securite: [{ id: 'formId', name: 'Form1', completed: true }] },
  },
];
const mockDataUsers: User[] = [
  {
    name: 'Rhonda Griffin',
    id: '0D1F1873-0BB1-967F-482A-D26ACBE1D3D7',
    roles: ['OperatorId', 'SafetyTechnicianId'],
    packs: [],
  },
  {
    name: 'Beverly Flynn',
    id: '076694EB-D3DA-8D1C-82CE-0BEE7484995F',
    roles: ['QualityControlSpecialistId'],
    packs: ['unId'],
  },
  {
    name: 'Kalia Olsen',
    id: '84C0D878-6431-E3AA-7957-6393A7BA7DBD',
    roles: [],
    packs: ['unId', 'deuxId'],
  },
  {
    name: 'Liberty Bolton',
    id: '8CBD1742-197E-4422-AD3C-195F60417BE6',
    roles: ['RecruiterId'],
    packs: ['sixId', 'quatesId'],
  },
  {
    name: 'Hashim Richards',
    id: 'C713F32C-C1BC-7212-7738-B2ACB22A739E',
    roles: ['OperatorId', 'SafetyTechnicianId', 'QualityControlSpecialistId', 'RecruiterId'],
    packs: mockPackData.map((pack) => pack.id),
  },
];

const mockDataMembers: Array<Member> = [
  {
    id: '1',
    name: 'Glen Tremoille',
    login: 'gtremoille0@photobucket.com',
  },
  {
    id: '2',
    name: 'Moina Ferby',
    login: 'mferby1@mlb.com',
  },
  {
    id: '3',
    name: 'Tan Matten',
    login: 'tmatten2@wisc.edu',
  },
  {
    id: '4',
    name: 'Noel Chaloner',
    login: 'nchaloner3@ifeng.com',
  },
];

export const mockWORKSITE: WorkSiteProps = {
  hasChanged: false,
  id: 'IDDeMonChantier',
  name: 'bonjour je suis un nom2',
  agency: 'uneAgence',
  postalCode: '012120',
  sharePointSiteName: 'SharePointName',
  sharePointLibraryName: 'SharePointLibraryName',
  logo: 'Alors la le logo ça vas être fun ...',

  standardPacks: mockPackData,
  otherPacks: mockPackData.map((elem) => ({ ...elem, id: `${elem.id}other` })),

  reports: mockDataReports,

  users: mockDataUsers,

  members: mockDataMembers,
};

const mockWorksiteList: Array<SimpleWorkSite> = [
  {
    id: 'jesuisunId',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Petit Nom Minion',
    status: WorkSiteStatus.COMPLETED,
  },
  {
    id: 'jesuisunIdAussi',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: false,
    name: "Je suis un grand nom de chantier histoire de pousser les limites de l'affichage et d'emerder le dev front end qui doit gérer des long noms",
    status: WorkSiteStatus.INCOMPLETE,
  },
  {
    id: 'IDTruc',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de chantier',
    status: WorkSiteStatus.CLOSED,
  },
  {
    id: 'IDSomething',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de Chantier',
    status: WorkSiteStatus.INCOMPLETE,
  },
  {
    id: 'IDMachin',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: false,
    name: 'Nom de ChAnTiEr',
    status: WorkSiteStatus.CLOSED,
  },
  {
    id: 'BonjourId',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de CHANTIER',
    status: WorkSiteStatus.CLOSED,
  },
];

const mockListChantier = ['Chantier Bonjour', 'Chantier un autre nom', 'Un autre chantier'];
const mockAgencyList = ['Une agence', 'uneAgence', 'deux Agences!'];

export const mockUsersList: Array<GenericIdNameAttribute & { agency: string }> = [
  {
    name: 'Reginaldo Cyneburga',
    id: '434f400a-a0b2-4c6b-acd8-7a829be57d08',
    agency: mockAgencyList[0],
  },
  {
    name: 'Amina Pumayyaton',
    id: 'd6e495d3-8e16-4184-9a47-ffdd43d403b0',
    agency: mockAgencyList[0],
  },
  {
    name: 'Lysandra Viggo',
    id: '5f60746f-fb44-4502-8c7a-7cba64547d04',
    agency: mockAgencyList[1],
  },
  {
    name: 'Rhonda Griffin',
    id: '0D1F1873-0BB1-967F-482A-D26ACBE1D3D7',
    agency: mockAgencyList[2],
  },
];

/*
TODO KTY MOCK DATA
 */

class WorkSiteStore implements AbortRequestsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/WorkSiteStore/worksiteStore.ts';

  clientHTTP: IClientHTTP;

  // Ne pas mettre dans resetStore
  shouldAbortRequests = false;

  logger: CustomLogger;

  RootStore: RootStore;

  useMockData: boolean; // TODO KTY To delete !

  agencyList: Array<string> = [];

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.logger = logger;
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;
    this.useMockData = process.env.REACT_APP_QC_WORK_SITE_MOCK_DATA === 'true';
    makeAutoObservable(
      this,
      {
        agencyList: observable,
        getAllWorkSite: action,
        getWorkSite: action,
        getAllWorkSiteName: action,
        getAllAgencyName: action,
        saveWorkSite: action,
        getAllUsers: action,
      },
      { autoBind: true },
    );
  }

  /**
   * @url: /WorkSite/getAllWorkSite
   */
  async getAllWorkSite(): Promise<Array<SimpleWorkSite>> {
    if (this.useMockData) {
      return mockWorksiteList;
    }
    try {
      if (!this.shouldAbortRequests) {
        const body = {};
        const response = await this.clientHTTP.post<BaseResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(API_WORK_SITE_LIST),
          body,
          {
            withCredentials: true,
          },
        );
        if (200 <= response.status && response.status <= 299) {
          if (response) {
            // TODO KTY return response here
            return mockWorksiteList;
          }
          return mockWorksiteList;
        } else {
          // const error = new Error(
          //   `Une erreur est survenue lors de l'envoi de la déclaration: Statut: ${response.data.status}, Message: ${response.data.message}`,
          // );

          errorHandler(WorkSiteStore.TAG, 'error worksitestore', 'getWorkSiteList', 'error');
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      errorHandler(WorkSiteStore.TAG, error, 'getWorkSiteList');
      return [];
    }
  }

  /**
   * @url: /WorkSite/getworksite/:workSiteId
   */
  async getWorkSite(workSiteId: SimpleWorkSite['id']): Promise<WorkSiteProps | undefined> {
    if (this.useMockData) {
      return mockWORKSITE;
    }

    try {
      if (!this.shouldAbortRequests) {
        const body = {};
        const url = API_WORK_SITE_BY_ID.replace(':worksiteId', workSiteId);
        const response = await this.clientHTTP.post<BaseResponse>(this.RootStore.CommonStore.chooseBaseUrl(url), body, {
          withCredentials: true,
        });
        if (200 <= response.status && response.status <= 299) {
          if (response) {
            // TODO KTY return response here
            return mockWORKSITE;
          }
          return mockWORKSITE;
        } else {
          // const error = new Error(
          //   `Une erreur est survenue lors de l'envoi de la déclaration: Statut: ${response.data.status}, Message: ${response.data.message}`,
          // );

          errorHandler(WorkSiteStore.TAG, 'error worksitestore', 'getWorkSite', 'error');
          return undefined;
        }
      } else {
        return undefined;
      }
    } catch (error) {
      errorHandler(WorkSiteStore.TAG, error, 'getWorkSite');
      return undefined;
    }
  }

  /**
   * @url:  /WorkSite/saveworksite/:workSiteId
   */
  async saveWorkSite(workSiteId: WorkSiteProps): Promise<void> {
    console.log('saving');
    if (this.useMockData) {
      const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
      await delay(5000);
      return;
    }
  }

  /**
   * @url:  /WorkSite/getAllWorkSiteName
   */
  async getAllWorkSiteName(): Promise<Array<string>> {
    if (this.useMockData) {
      return mockListChantier;
    }

    try {
      if (!this.shouldAbortRequests) {
        const body = {};
        const response = await this.clientHTTP.post<BaseResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(API_WORK_SITE_ALL_NAMES),
          body,
          {
            withCredentials: true,
          },
        );
        if (200 <= response.status && response.status <= 299) {
          if (response) {
            // TODO KTY return response here
            return mockListChantier;
          }
          return mockListChantier;
        } else {
          // const error = new Error(
          //   `Une erreur est survenue lors de l'envoi de la déclaration: Statut: ${response.data.status}, Message: ${response.data.message}`,
          // );

          errorHandler(WorkSiteStore.TAG, 'error worksitestore', 'getAllWorkSiteName', 'error');
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      errorHandler(WorkSiteStore.TAG, error, 'getAllWorkSiteName');
      return [];
    }
  }

  /**
   * @url:  /WorkSite/getAllAgencyName
   */
  async getAllAgencyName() {
    if (this.agencyList.length > 0) {
      return this.agencyList;
    }
    if (this.useMockData) {
      this.agencyList = mockAgencyList;
      return mockAgencyList;
    }

    try {
      if (!this.shouldAbortRequests) {
        const body = {};
        const response = await this.clientHTTP.post<BaseResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(API_WORK_SITE_ALL_AGENCY),
          body,
          {
            withCredentials: true,
          },
        );
        if (200 <= response.status && response.status <= 299) {
          if (response) {
            // TODO KTY return response here
            this.agencyList = mockAgencyList;
            return mockAgencyList;
          }
          this.agencyList = mockAgencyList;
          return mockAgencyList;
        } else {
          // const error = new Error(
          //   `Une erreur est survenue lors de l'envoi de la déclaration: Statut: ${response.data.status}, Message: ${response.data.message}`,
          // );

          errorHandler(WorkSiteStore.TAG, 'error worksitestore', 'getAllAgencyName', 'error');
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      errorHandler(WorkSiteStore.TAG, error, 'getAllAgencyName');
      return [];
    }
  }

  /**
   * @url:  /?????????/?????????
   */
  async getAllUsers(): Promise<Array<GenericIdNameAttribute & { agency: string }>> {
    if (this.useMockData) {
      return mockUsersList;
    }

    try {
      if (!this.shouldAbortRequests) {
        const body = {};
        const response = await this.clientHTTP.post<BaseResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(API_WORK_SITE_ALL_AGENCY),
          body,
          {
            withCredentials: true,
          },
        );
        if (200 <= response.status && response.status <= 299) {
          if (response) {
            // TODO KTY return response here
            return mockUsersList;
          }
          return mockUsersList;
        } else {
          // const error = new Error(
          //   `Une erreur est survenue lors de l'envoi de la déclaration: Statut: ${response.data.status}, Message: ${response.data.message}`,
          // );

          errorHandler(WorkSiteStore.TAG, 'error worksitestore', 'getAllAgencyName', 'error');
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      errorHandler(WorkSiteStore.TAG, error, 'getAllAgencyName');
      return [];
    }
  }

  setShouldAbortRequests(shouldAbortRequests: boolean): void {
    this.shouldAbortRequests = shouldAbortRequests;
  }
}

export default WorkSiteStore;
