import React, { FunctionComponent } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  Button,
  ButtonProps,
  Card,
  IconButton,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close, Download } from '@mui/icons-material';
import { WorkSiteSummaryProps } from '../hookWorkSiteSummaryWrapper';
import { NEW_WORKSITE_DEFAULT_ID, WSActType } from '../typesWorkSite';
import useGeneralInfo from './hookGeneralInfo';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type TextFieldInput = { type: InputType.TEXTFIELD; inputs: Array<TextFieldProps> };
type AutocompleteAction = {
  type: InputType.AUTOCOMPLETE;
  inputs: Array<
    Omit<AutocompleteProps<string, false, false, boolean>, 'renderInput'> & {
      textfieldprops?: TextFieldProps;
    }
  >;
};
type UploadAction = { type: InputType.UPLOAD; inputs: Array<ButtonProps> };
type UploadedAction = { type: InputType.UPLOADED; inputs: Array<{ file?: File; url?: string }> };

type PossibleInput = TextFieldInput | AutocompleteAction | UploadAction | UploadedAction;

enum InputType {
  TEXTFIELD,
  AUTOCOMPLETE,
  UPLOAD,
  UPLOADED,
}

const GeneralInfo: FunctionComponent<WorkSiteSummaryProps> = (props: WorkSiteSummaryProps) => {
  const { t } = useTranslation('moduleWorkSite');
  const translate = (key: string) => t(key) || '';
  const { workSiteNameList, agencyList, onUserFileUpload, deleteUserFile, originalName } = useGeneralInfo(props);

  const getLogoMini = (url: string, file?: File) => {
    return (
      <Card key={url}>
        <Stack alignItems={'center'} flexDirection={'row'} style={{ padding: '0.2rem' }} gap={'0.2rem'}>
          <img
            alt={translate('work_site_summary_informations_logo')}
            src={url}
            style={{ height: '2rem', maxWidth: '5rem' }}
          />
          <Typography overflow={'hidden'}>{file?.name || ''}</Typography>
          <IconButton onClick={deleteUserFile}>
            <Close />
          </IconButton>
        </Stack>
      </Card>
    );
  };

  function getInput(title: string, inputChoice: PossibleInput) {
    const getInputs = () => {
      switch (inputChoice.type) {
        case InputType.TEXTFIELD:
          return (
            <>
              {(inputChoice.inputs as TextFieldInput['inputs']).map((input) => (
                <TextField key={input.placeholder} size={'small'} {...input} />
              ))}
            </>
          );
        case InputType.AUTOCOMPLETE:
          return (
            <>
              {(inputChoice.inputs as AutocompleteAction['inputs']).map((input) => (
                <Autocomplete
                  key={input.placeholder}
                  size={'small'}
                  {...input}
                  renderInput={(param) => (
                    <TextField {...param} placeholder={input.placeholder} {...input.textfieldprops} />
                  )}
                />
              ))}
            </>
          );
        case InputType.UPLOAD:
          return (
            <>
              {(inputChoice.inputs as UploadAction['inputs']).map((input) => (
                <Button
                  key={title + input.placeholder}
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<Download />}
                  {...input}
                >
                  {input.children}
                  <VisuallyHiddenInput type="file" accept={'image/*'} onChange={onUserFileUpload} multiple={false} />
                </Button>
              ))}
            </>
          );
        case InputType.UPLOADED:
          let url = typeof props.workSite.logo === 'string' ? (props.workSite.logo as string) : '';
          const file = typeof props.workSite.logo === 'string' ? undefined : (props.workSite.logo as File);
          if (file && !url) {
            url = URL.createObjectURL(file);
          }
          return getLogoMini(url, file);
        default:
          return <></>;
      }
    };

    return (
      <Stack gap={'0.2rem'}>
        <Typography fontWeight={'bold'} variant={'body1'}>
          {t(title)}
        </Typography>
        {getInputs()}
      </Stack>
    );
  }

  function getHelperText() {
    //TODO KTY ajouter une info quand on change le nom d'un worksite ouvert (comme quoi 'est du renommage) et ne rien mettre si c'est un nouveau worksite
    if (props.workSite.name && workSiteNameList.includes(props.workSite.name)) {
      return '';
    }
    if (props.workSite.id === NEW_WORKSITE_DEFAULT_ID) {
      return '';
    }
    if (originalName) {
      return `You are renaming the worksite: ${originalName}`;
    }
  }

  const getAllInputsDef = () => {
    return [
      // Name
      getInput(t('work_site_summary_informations_name'), {
        type: InputType.AUTOCOMPLETE,
        inputs: [
          {
            placeholder: translate('work_site_summary_informations_name'),
            freeSolo: true,
            options: workSiteNameList,
            value: props.workSite.name || '',
            onChange: (e, value) => {
              props.setWorkSite({ type: WSActType.NAME, value: value || '' });
            },
            onBlur: (e) => {
              props.setWorkSite({ type: WSActType.NAME, value: (e.target as HTMLInputElement).value || '' });
            },
            textfieldprops: { helperText: getHelperText() },
            disabled: props.workSite.id !== NEW_WORKSITE_DEFAULT_ID,
          },
        ],
      }),
      // Agency
      getInput(t('work_site_summary_informations_agency'), {
        type: InputType.AUTOCOMPLETE,
        inputs: [
          {
            placeholder: translate('work_site_summary_informations_agency'),
            value: props.workSite.agency || '',
            options: agencyList,
            onChange: (e, value) => {
              props.setWorkSite({ type: WSActType.AGENCY, value: value || '' });
            },
          },
        ],
      }),
      // Postal Code
      getInput(t('work_site_summary_informations_postal_code'), {
        type: InputType.TEXTFIELD,
        inputs: [
          {
            placeholder: translate('work_site_summary_informations_postal_code'),
            value: props.workSite.postalCode || '',
            onChange: (e) =>
              props.setWorkSite({
                type: WSActType.POSTCODE,
                value: (e.target as HTMLInputElement).value || '',
              }),
          },
        ],
      }),
      // SharePoint
      getInput(t('work_site_summary_informations_sharepoint'), {
        type: InputType.TEXTFIELD,
        inputs: [
          {
            placeholder: translate('work_site_summary_informations_sharepoint_site_name'),
            onChange: (e) =>
              props.setWorkSite({
                type: WSActType.SHAREPOINTSITENAME,
                value: (e.target as HTMLInputElement).value || '',
              }),
          },
          {
            placeholder: translate('work_site_summary_informations_sharepoint_lib_name'),
            onChange: (e) =>
              props.setWorkSite({
                type: WSActType.SHAREPOINTLIBRARYNAME,
                value: (e.target as HTMLInputElement).value || '',
              }),
          },
        ],
      }),
      // Logo
      props.workSite.logo
        ? getInput(t('work_site_summary_informations_logo'), {
            type: InputType.UPLOADED,
            inputs: [],
          })
        : getInput(t('work_site_summary_informations_logo'), {
            type: InputType.UPLOAD,
            inputs: [
              {
                children: translate('work_site_summary_informations_upload_logo'),
                placeholder: translate('work_site_summary_informations_logo'),
              },
            ],
          }),
    ];
  };
  return (
    <Stack gap={'0.3rem'} data-cy={'GeneralInfo'}>
      {getAllInputsDef()}
    </Stack>
  );
};

export default GeneralInfo;
