import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MandatoryFieldType } from './types';
import { FieldType } from '90.quickConnect.Models/enums';
import { QCFieldComponentRoot } from '50.quickConnect.Fields/const';

const MandatoryField = ({ fieldDesc, children }: MandatoryFieldType) => {
  const theme = useTheme();
  const { t } = useTranslation('declaration');
  const { mandatory, errors, fieldType, isVisible } = fieldDesc;

  const mandatoryIndicator = useMemo(() => {
    // pour mettre l'astérisque que sur les champs obligatoires
    const requiredError = errors?.filter((er) => er === t('errors_mandatory_field') || t('errors_invalid_field_items'));
    const hasError = requiredError && requiredError.length > 0;

    if (mandatory || hasError) {
      return (
        <span
          style={{
            color: hasError ? theme.palette.error.main : theme.palette.text.disabled,
            fontWeight: 'bolder',
            marginRight: '0.5em',
          }}
          data-cy={`mandatory-star${fieldDesc.fullPathId}${hasError ? 'error' : ''}`}
        >
          *
        </span>
      );
    }
  }, [errors, fieldDesc.fullPathId, mandatory, t, theme.palette.error.main, theme.palette.text.disabled]);

  return (
    <Box sx={{ display: 'flex' }} data-cy={QCFieldComponentRoot + fieldDesc.fullPathId}>
      <>
        {(isVisible && mandatoryIndicator) ?? (
          <span style={{ marginRight: fieldType === FieldType.Include ? '0rem' : '1em' }}> </span>
        )}
        {children}
      </>
    </Box>
  );
};
export default MandatoryField;
