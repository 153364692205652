import { QcOperator, OperatorResult } from '90.quickConnect.Models/enums';
import { BaseQCOperator, ConstStringOperator, ConstNumberOperator } from '90.quickConnect.Models/models';

const getConstValue = (baseQCOperator: BaseQCOperator): [string | number | undefined, OperatorResult] => {
  switch (baseQCOperator.type) {
    case QcOperator.ConstString:
      const constString = baseQCOperator as ConstStringOperator;
      let { value } = constString;

      if (value.includes('"') && !(value.startsWith('"') && value.endsWith('"'))) {
        value = `'${value}'`;
      } else if (value.includes("'") && !(value.startsWith("'") && value.endsWith("'"))) {
        value = `"${value}"`;
      }

      return [value, OperatorResult.STRING];
    case QcOperator.ConstDouble:
      const constDouble = baseQCOperator as ConstNumberOperator;
      const operatorResult: OperatorResult = Number.isInteger(constDouble.value)
        ? OperatorResult.INT
        : OperatorResult.DOUBLE;
      return [constDouble.value, operatorResult];
    default:
      return [undefined, OperatorResult.ERROR];
  }
};

export default getConstValue;
