/* eslint max-lines-per-function: off */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import JSONBig from 'json-bigint';
import { UseDataProps } from './types';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import useGetDeclarationContext from '10.quickConnect.app/components/domain/Declaration/hooks/useGetFormContext';
import useHydrateDeclarationWithEquipment from '10.quickConnect.app/components/domain/Declaration/hooks/useHydrateDeclaration/useHydrateDeclarationWithEquipment';
import useHydrateDeclarationWithInternalData from '10.quickConnect.app/components/domain/Declaration/hooks/useHydrateDeclaration/useHydrateDeclarationWithInternalData';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { useStore } from '30.quickConnect.Stores';
import { FieldDesc, ItemData, WorkflowData, DeclarationContext, EntityData } from '90.quickConnect.Models/models';
import { errorHandler, flatten } from '80.quickConnect.Core/helpers';
import { mapFieldDesc } from '90.quickConnect.Models/mappings';
import { FormType, WorkflowStatus } from '90.quickConnect.Models/enums';
import {
  setHistoricValues,
  getSharedListIds,
} from '10.quickConnect.app/components/domain/Declaration/helpers/initForm';
import {
  getEntityDataByDeepLink,
  setDeepLinkValues,
} from '10.quickConnect.app/components/domain/Declaration/helpers/initForm/deepLink';
import { useFieldAutoComplete } from '50.quickConnect.Fields/hooks/useFieldAutoComplete';
import { mapDesactivatedAutomation } from '90.quickConnect.Models/mappings';
import { desactivatedAutomationWkf } from '10.quickConnect.app/components/domain/Declaration/helpers/initForm/desactivatedAutomationWkf';
import { useHydrateDefaultChoices } from '10.quickConnect.app/components/domain/Declaration/hooks/useHydrateDeclaration/useHydrateDefaultChoices';
import { updatePropertyFieldOrChild } from '20.formLib/helpers/updateField/updatePropertyFieldOrChild';

const useData = (isDuplicated: boolean | undefined, isDeepLink: boolean | undefined): UseDataProps => {
  // On récupère le store
  const {
    DeclarationStore: {
      openDialog,
      openDeclarationDialog,
      isEditingCurrentDeclaration: openSaveDraftDeclarationDialog,
      setEditableDeclaration,
      isDeletedDeclarationContext,
      setIsDeletedDeclarationContext,
      setCloseDrawer,
      setDeclarationStoreId,
      declarationId: dclId,
      setIsDeepLink,
      setUrlToUseForImagesBank,
    },
    EquipmentsStore: { getEntityData, resetIsInDataSource, synchronizeEquipementsMultipleAsync, setIsInDataSource },
    SharedListStore: { getSharedLists },
    SessionStore: { lastURLSaved, setLastURLSaved },
    LoginStore: {
      signInInfos: { userUPN },
    },
  } = useStore();

  // TAG
  const tag = '10.quickConnect.app/components/domain/Declaration/hooks.useData.ts';

  // On récupère des données depuis l'URL
  const { formId, inboxId, activityId, declarationId, entityInstanceId } = useParams();
  const { search } = useLocation();

  const [context, setCurrentContext] = useState<DeclarationContext>();
  const [declaration, setDeclaration] = useState<FieldDesc[]>();
  const [declarationData, setDeclarationData] = useState<ItemData>({});
  const [entityData, setEntityData] = useState<EntityData>();

  const [currentActivityId, setCurrentActivityId] = useState<string | undefined>(activityId ?? uuidv4());

  // On appelle les custom hooks
  const { getContext, getContextByDeepLink, getEquipmentByDeepLink, checkEntityDataWithFormContext } =
    useGetDeclarationContext();
  const { hydrateDeclarationWithEquipmentAttributesAsync } = useHydrateDeclarationWithEquipment();
  const { initInternalFieldData } = useHydrateDeclarationWithInternalData();
  const { populateDefaultChoices } = useHydrateDefaultChoices();

  const { registerValidState } = useFieldAutoComplete();

  // Permet de rendre la déclaration éditable ou non (avant on se basait sur le isDraft mais cela bloquait dans le cas d'une duplication de déclaration)
  const [isEditable, setIsEditable] = useState<boolean>(false);

  // Problème sur la duplication des ids des déclarations - contournement par une Ref
  const declarationIdRef = useRef<string>('');

  const initializeFields = useCallback(
    async (newContext: DeclarationContext, entityDataFromDL?: EntityData): Promise<FieldDesc[]> => {
      const {
        formBody,
        formType,
        itemData,
        editedData,
        entitySchemaId,
        templateBodies,
        isDraft,
        injectedData,
        internalData,
        name: formName,
        tokenSAS,
      } = newContext;

      // On set les valeurs dans le logger concernant le DeclarationContext
      CustomLogger.getInstance().setDeclarationInfos(formType, formName, userUPN);

      const dclIsEditable = !!(isDraft || declarationId === undefined || isDeepLink || isDuplicated);
      setIsEditable((): boolean => dclIsEditable);

      setEditableDeclaration(dclIsEditable);

      setUrlToUseForImagesBank(tokenSAS);

      // On parse les champs du formulaire
      const parsedBody: FieldDesc[] = formBody ? JSONBig.parse(formBody) : [];
      const initializedFields: FieldDesc[] = mapDesactivatedAutomation(
        parsedBody.map((field) => mapFieldDesc(field, undefined, templateBodies, newContext)),
        !dclIsEditable,
      );

      const newFlattenFields = flatten(initializedFields ?? [], (i) => i.items);

      // On récupère les sharedLists - On pense aussi à vérifier les groupes répétables et les groupTemplate
      const sharedListIds = getSharedListIds(newFlattenFields);

      if (sharedListIds.length > 0) {
        await getSharedLists(sharedListIds);
      }

      populateDefaultChoices(initializedFields);

      // si on est dans le cas d'une duplication de déclaration
      if (isDuplicated) {
        newContext.id = undefined;
      }

      // Dans le cas des DeepLink
      if (isDeepLink) {
        // Mise a jour des données
        const autoCompleteInternal = { registerValidState, setIsInDataSource };
        await setDeepLinkValues(initializedFields, injectedData, autoCompleteInternal);

        // Recherche des entityData si besoin
        const entityInfos: Record<string, { instanceId: string; schemaId: string }> | undefined =
          await getEntityDataByDeepLink(newFlattenFields, synchronizeEquipementsMultipleAsync);

        if (entityInfos) {
          for (const key in entityInfos) {
            const { instanceId, schemaId } = entityInfos[key];
            const entityDataToSet = await getEntityData(schemaId, instanceId);
            const field: FieldDesc = initializedFields.find(({ fullPathId }: FieldDesc) => fullPathId === key)!;

            initializedFields.forEach((item: FieldDesc) => {
              updatePropertyFieldOrChild(key, field, 'entityData', entityDataToSet);
            });
          }
        }
      }

      if (editedData && formType !== FormType.Workflow) {
        // si on a une déclaration on restaure les champs tels qu'ils l'étaient
        const declarationIdentifier = dclId !== '' && newContext.id ? dclId : declarationIdRef.current;
        await setHistoricValues(
          editedData,
          templateBodies,
          newFlattenFields,
          declarationIdentifier,
          newContext.id ? false : true,
          false,
          formType,
        );

        // Initialisation des internalData
        if (internalData) {
          await initInternalFieldData(newFlattenFields, internalData, dclIsEditable, !!isDuplicated);
        }
      }
      // Si il n'y a pas de données de déclaration
      else {
        if (itemData) {
          // On retourne à l'étape précédent du workflow, pour simplifier le workflow, on part du principe qu'on doit avoir le bon currentStep...
          const { Workflow, PrefilledData } = itemData;
          if (Workflow) {
            const { currentWorkflowState } = Workflow;
            const index = parsedBody.findIndex((f) => f.id === Workflow.currentStep);
            switch (currentWorkflowState) {
              case WorkflowStatus.ToReview:
                Workflow.currentStep = parsedBody[index - 1].id;
                Workflow.currentWorkflowState = WorkflowStatus.None;
                break;
              case WorkflowStatus.ToForward:
                Workflow.currentStep = parsedBody[index + 1].id;
                Workflow.currentWorkflowState = WorkflowStatus.None;
                break;
            }

            // On réadapte le desactivatedAutomation
            if (dclIsEditable) desactivatedAutomationWkf(initializedFields, Workflow.currentStep);

            // Verifions si des données sont déjà enregistrées:
            if (editedData) {
              await setHistoricValues(
                editedData,
                templateBodies,
                newFlattenFields,
                dclId,
                isDuplicated ?? false,
                false,
                formType,
                Workflow.currentStep,
              );

              // Initialisation des internalData
              if (internalData) {
                initInternalFieldData(newFlattenFields, internalData, dclIsEditable, !!isDuplicated);
              }
            } else if (PrefilledData) {
              await setHistoricValues(
                JSON.stringify(PrefilledData),
                templateBodies,
                newFlattenFields,
                dclId,
                isDuplicated ?? false,
                true,
                formType,
                Workflow.currentStep,
              );
            }
          } else if (PrefilledData) {
            const declarationIdentifier = dclId !== '' && newContext.id ? dclId : declarationIdRef.current;

            // Gestion des inboxes (Attention, on ne prend pas en compte les PrefilledData dans le cas d'une reprise de saisie WorkFlow après une mise en pause)
            if (editedData) {
              await setHistoricValues(
                editedData,
                templateBodies,
                newFlattenFields,
                declarationIdentifier,
                !!newContext.id,
                false,
                formType,
              );
            } else {
              await setHistoricValues(
                JSON.stringify(PrefilledData),
                templateBodies,
                newFlattenFields,
                declarationIdRef.current,
                false,
                true,
                formType,
              );

              // Initialisation des internalData
              if (internalData) {
                initInternalFieldData(newFlattenFields, internalData, dclIsEditable, !!isDuplicated);
              }
            }
          }
          if (formType === FormType.Workflow && itemData.Workflow === undefined) {
            const newItemData: ItemData = {
              ...itemData,
              Workflow: {
                currentStep: parsedBody[0].id,
                currentWorkflowState: WorkflowStatus.None,
                historicalStepDataList: [],
              } as WorkflowData,
            };

            // desactivatedAutomation
            desactivatedAutomationWkf(initializedFields, parsedBody[0].id);

            setDeclarationData(newItemData);
          } else {
            setDeclarationData(itemData);
          }
        }
        // Si on est sur un workflow, on initialise ItemData
        else if (formType === FormType.Workflow) {
          const newItemData: ItemData = {
            Workflow: {
              currentStep: parsedBody[0].id,
              currentWorkflowState: WorkflowStatus.None,
              historicalStepDataList: [],
            } as WorkflowData,
          };
          setDeclarationData(newItemData);

          // Verifions si des données sont déjà enregistrées:
          if (editedData) {
            await setHistoricValues(
              editedData,
              templateBodies,
              newFlattenFields,
              dclId,
              newContext.id ? true : false,
              false,
              formType,
            );
          }

          // Initialisation des internalData
          if (internalData) {
            initInternalFieldData(newFlattenFields, internalData, dclIsEditable, !!isDuplicated);
          }
        }
        // Si on est pas sur une déclaration existante, on set les valeur des binding
        if (entitySchemaId && (entityInstanceId || entityDataFromDL)) {
          const getEntity = async (): Promise<FieldDesc[]> => {
            let newEntityData: EntityData | undefined;
            if (!entityDataFromDL && entityInstanceId) {
              newEntityData = await getEntityData(entitySchemaId, entityInstanceId);
            } else {
              newEntityData = entityDataFromDL;
            }

            if (newEntityData) {
              setEntityData(newEntityData);

              /* eslint-disable-next-line  @typescript-eslint/no-non-null-assertion */
              const entityFieldsInitialized = hydrateDeclarationWithEquipmentAttributesAsync(
                initializedFields,
                newEntityData!,
              );

              return entityFieldsInitialized;
            }
            return initializedFields;
          };
          const fieldsEntityInitialized = await getEntity();

          return fieldsEntityInitialized;
        }
      }
      return initializedFields;
    },
    [
      userUPN,
      declarationId,
      isDeepLink,
      isDuplicated,
      setEditableDeclaration,
      setUrlToUseForImagesBank,
      populateDefaultChoices,
      getSharedLists,
      registerValidState,
      setIsInDataSource,
      synchronizeEquipementsMultipleAsync,
      getEntityData,
      dclId,
      initInternalFieldData,
      entityInstanceId,
      hydrateDeclarationWithEquipmentAttributesAsync,
    ],
  );

  const flattenFields = useMemo(() => flatten(declaration ?? [], (i) => i.items), [declaration]);

  // On récupère le contexte
  useEffectOnce(() => {
    if (lastURLSaved) setLastURLSaved(undefined);
    declarationIdRef.current = uuidv4();
    setDeclarationStoreId(
      declarationId && declarationId !== '' && !isDuplicated ? declarationId : declarationIdRef.current,
    );

    // Set l'observable pour le deepLink (utile pour contourner certaines règles dans les useEffect des fields).
    setIsDeepLink(!!isDeepLink);

    // Cas du deepLink
    if (isDeepLink) {
      (async () => {
        try {
          const params: URLSearchParams = new URLSearchParams(search);

          let newFields: FieldDesc[];

          if (!params.has('formname'))
            throw new Error(
              '[Client Web] useData file Declaration useEffectOnce function failed: Required formname query parameter when isDeepLink equals true.',
            );

          const newContext = await getContextByDeepLink(params);

          if (params.has('entityInstanceCode')) {
            const entityDataDL = await getEquipmentByDeepLink(params.get('entityInstanceCode')!);

            if (!entityDataDL)
              throw new Error(
                `[Client Web] - Declaration hook file, useEffectOnce failed: Aucune donnée d'équipement récupérée`,
              );

            if (!newContext) throw new Error(`Pas de context récupéré pour le formName: "${params.get('formname')}"`);

            checkEntityDataWithFormContext(entityDataDL, newContext);

            newFields = await initializeFields(newContext, entityDataDL);
            setDeclaration(newFields);
            setCurrentContext(newContext);
            if (newContext?.activityId) {
              setCurrentActivityId(newContext?.activityId);
            }
          } else {
            if (newContext) {
              newFields = await initializeFields(newContext);
              setDeclaration(newFields);
              setCurrentContext(newContext);
              if (newContext?.activityId) {
                setCurrentActivityId(newContext?.activityId);
              }
            }
          }
        } catch (error) {
          errorHandler(tag, error, 'useEffectOnce par deeplink', 'error');
        }
      })();
    }
    // Méthode classique
    if (formId) {
      (async () => {
        // On récupère le formulaire
        const newContext = await getContext(isDuplicated, formId, declarationId, inboxId, entityInstanceId);

        if (newContext) {
          try {
            const newFields = await initializeFields(newContext);
            setDeclaration(newFields);
            setCurrentContext(newContext);
            if (newContext?.activityId) {
              setCurrentActivityId(newContext?.activityId);
            }
          } catch (error) {
            errorHandler(tag, error, 'useEffectOnce par ouverture de formulaire', 'error');
          }
        }
      })();
    }
  });

  const onGoBack = useCallback(
    function () {
      openDialog();
    },
    [openDialog],
  );
  const refIsDeletedDeclarationContext = useRef<boolean>(isDeletedDeclarationContext);
  useEffect(() => {
    if (refIsDeletedDeclarationContext.current) {
      setIsDeletedDeclarationContext(false);
    }
  }, [setIsDeletedDeclarationContext]);

  useEffect(() => {
    return () => {
      setCloseDrawer();
    };
  }, [setCloseDrawer]);
  useEffect(() => {
    if (openSaveDraftDeclarationDialog && isEditable) {
      window.history.pushState(null, '', document.URL);
      window.addEventListener('popstate', onGoBack);

      return () => {
        resetIsInDataSource();
        CustomLogger.getInstance().resetDeclarationInfos();
        window.removeEventListener('popstate', onGoBack);
      };
    }
  }, [
    resetIsInDataSource,
    onGoBack,
    openSaveDraftDeclarationDialog,
    isEditable,
    openDeclarationDialog,
    setIsDeletedDeclarationContext,
  ]);

  return {
    context,
    entityData,
    declaration,
    flattenFields,
    declarationData,
    setDeclarationData,
    setDeclaration,
    currentActivityId,
    inboxId,
    declarationId: !isDuplicated && declarationId ? declarationId : declarationIdRef.current,
    isEditable,
    isDeletedDeclarationContext,
  };
};

export default useData;
