import { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationRef, DeclarationsRef, UseDataProps, VisibilitiesRef, VisibilityRef } from './types';
import { FieldDesc, AllFieldValueTypes, StepDesc, DeclarationContext } from '90.quickConnect.Models/models';
import updateVisibilityFieldOrChild from '20.formLib/helpers/updateField/updateVisibilityFieldOrChild';
import updateErrorsFieldOrChild from '20.formLib/helpers/updateField/updateErrorsFieldOrChild';
import updateValueFieldOrChild from '20.formLib/helpers/updateField/updateValueFieldOrChild';
import { FormType } from '90.quickConnect.Models/enums';
import updateStatedIdFieldOrChild from '20.formLib/helpers/updateField/updateStatedIdFieldOrChild';
import { useStore } from '30.quickConnect.Stores';
import { flatten } from '80.quickConnect.Core/helpers';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import useFormEventQCScript from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript';
import updateReadOnlyFieldOrChild from '20.formLib/helpers/updateField/updateReadOnlyFieldOrChild';
import updateMandatoryFieldOrChild from '20.formLib/helpers/updateField/updateMandatoryFieldOrChild';
import updateItemsFieldOrChild from '20.formLib/helpers/updateField/updateItemsFieldOrChild';
import { QCSFunctions } from '20.formLib/DeclarationContainer/contexts/types';
import { updatePropertyFieldOrChild } from '20.formLib/helpers/updateField/updatePropertyFieldOrChild';

const useData = (
  context: DeclarationContext,
  setDeclaration: Dispatch<SetStateAction<FieldDesc[] | undefined>>,
  declaration: FieldDesc[],
  formType: FormType,
  isDraft: boolean,
  isEditable: boolean,
  qcScriptObj?: string,
): UseDataProps => {
  const [canSubmit, setCanSubmit] = useState(false);
  const visibilitiesRef = useRef<VisibilitiesRef>([]);
  const errorsRef = useRef<Map<string, string>>(new Map<string, string>());
  const declarationUpdatedRef = useRef<DeclarationsRef>([]);
  const qcsFormEvent = useFormEventQCScript();

  const {
    DeclarationStore: { setIsUseDeclarationComponent, setIsEditingCurrentDeclaration },
    QCScriptStore: {
      setControllerMethod,
      setQCSFieldId,
      setQCSBaseFieldId,
      setFlattenFieldsByFullPathId,
      setFlattenFields,
    },
    EquipmentsStore: { findIsInDataSource },
  } = useStore();

  const { t: declarationTranslator } = useTranslation('declaration');

  const hasControllerMethod = useCallback((field: FieldDesc): boolean => {
    const { controller } = field;
    if (controller === undefined) return false;

    return controller !== '';
  }, []);

  const canUpdate = useCallback(
    (updatedFieldFullPathId: string, newValue: AllFieldValueTypes): boolean => {
      const flattenFields = flatten(declaration, (f: FieldDesc) => f.items);
      const fieldToCheck = flattenFields.find(
        (f: FieldDesc) => updatedFieldFullPathId.toLowerCase() === f.fullPathId.toLowerCase(),
      );
      if (!fieldToCheck) return true;
      const canUpdateBool: boolean = fieldToCheck.value === newValue ? hasControllerMethod(fieldToCheck) : true;

      if (canUpdateBool) {
        // On set l'observable controllerMethod afin d'avoir la methode a appeler pour les QCSCripts
        const controllerMethod = fieldToCheck.controller ?? '';

        // Prendre en compte le desactivatedAutomation
        // eslint-disable-next-line prefer-destructuring
        const desactivatedAutomation = fieldToCheck.desactivatedAutomation;

        setFlattenFields(flattenFields);
        setFlattenFieldsByFullPathId(updatedFieldFullPathId.toLowerCase(), newValue);
        if (controllerMethod && controllerMethod !== '' && !desactivatedAutomation) {
          setControllerMethod(controllerMethod);
          setQCSBaseFieldId(updatedFieldFullPathId);
          setQCSFieldId(fieldToCheck.id);
        }
      }

      return canUpdateBool;
    },
    [
      declaration,
      hasControllerMethod,
      setControllerMethod,
      setQCSBaseFieldId,
      setQCSFieldId,
      setFlattenFieldsByFullPathId,
      setFlattenFields,
    ],
  );

  const updateDeclarationRef = useCallback((updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => {
    const filteredByHidableFieldFullPathId =
      declarationUpdatedRef.current.filter(
        (declarationItem: DeclarationRef) => declarationItem.updatedFieldFullPathId === updatedFieldFullPathId,
      ).length > 0;

    declarationUpdatedRef.current = filteredByHidableFieldFullPathId
      ? declarationUpdatedRef.current.map((d) =>
          d.updatedFieldFullPathId === updatedFieldFullPathId ? { ...d, newValue: newValue } : { ...d },
        )
      : [...declarationUpdatedRef.current, { updatedFieldFullPathId, newValue }];
  }, []);

  const updateDeclaration = useCallback(
    (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => {
      setIsEditingCurrentDeclaration(true);
      setIsUseDeclarationComponent(true);
      if (canUpdate(updatedFieldFullPathId, newValue) || findIsInDataSource(updatedFieldFullPathId) === false) {
        setDeclaration((prev) => {
          if (prev && prev.length > 0) {
            prev.forEach((root) => {
              updatedFieldFullPathId = updatedFieldFullPathId.toLowerCase();
              updateValueFieldOrChild(updatedFieldFullPathId, root, newValue);
            });
            return [...prev];
          }
          return prev;
        });
        updateDeclarationRef(updatedFieldFullPathId, newValue);
      }
    },
    [
      setIsEditingCurrentDeclaration,
      setDeclaration,
      canUpdate,
      updateDeclarationRef,
      setIsUseDeclarationComponent,
      findIsInDataSource,
    ],
  );

  const isTheSameError = useCallback(
    (mandatoryFieldFullPathId: string, newErrors: string[]): boolean => {
      // const field = JSON.stringify(
      //   flatten(declaration, (i) => i.items).find(
      //     ({ fullPathId: fullId }: FieldDesc) => fullId === mandatoryFieldFullPathId,
      //   )!,
      // );

      // return errorsRef.current.get(mandatoryFieldFullPathId) === field;
      const field = flatten(declaration, (i) => i.items).find(
        ({ fullPathId: fullId }: FieldDesc) => fullId === mandatoryFieldFullPathId,
      );

      if (!field) return false;

      const { errors } = field;

      if (!errors) return false;

      if (errors.length === 0 || errors.length !== newErrors.length) return false;

      return errors.every((error: string) => newErrors.includes(error));
    },
    [declaration],
  );

  const updateErrorRef = useCallback(
    (mandatoryFieldFullPathId: string, newErrors: string[]) => {
      const field = JSON.stringify(
        flatten(declaration, (i) => i.items).find(
          ({ fullPathId: fullId }: FieldDesc) => fullId === mandatoryFieldFullPathId,
        )!,
      );

      errorsRef.current.set(mandatoryFieldFullPathId, field);
    },
    [declaration],
  );

  const updateFieldErrors = useCallback(
    (mandatoryFieldFullPathId: string, newErrors: string[]) => {
      if (!isTheSameError(mandatoryFieldFullPathId, newErrors)) {
        setDeclaration((prev) => {
          if (prev && prev.length > 0) {
            prev.forEach((root) => {
              updateErrorsFieldOrChild(
                mandatoryFieldFullPathId,
                root,
                newErrors,
                declarationTranslator('errors_invalid_field_items'),
              );
            });
            return [...prev];
          }
          return prev;
        });
        updateErrorRef(mandatoryFieldFullPathId, newErrors);
      }
    },
    [isTheSameError, setDeclaration, updateErrorRef, declarationTranslator],
  );

  const isTheSameVisibility = useCallback(
    (hidableFieldFullPathId: string, newVisibility: boolean): boolean => {
      const flattenFields = flatten(declaration, (f: FieldDesc) => f.items);
      const fieldToCheck = flattenFields.find((f: FieldDesc) => f.fullPathId === hidableFieldFullPathId);
      if (!fieldToCheck) return false;
      return fieldToCheck.isVisible === newVisibility;
    },
    [declaration],
  );

  const updateVisibilityRef = useCallback((hidableFieldFullPathId: string, newVisibility: boolean) => {
    const filteredByHidableFieldFullPathId =
      visibilitiesRef.current.filter(
        (visibilityItem: VisibilityRef) => visibilityItem.hidableFieldFullPathId === hidableFieldFullPathId,
      ).length > 0;

    visibilitiesRef.current = filteredByHidableFieldFullPathId
      ? visibilitiesRef.current.map((v) =>
          v.hidableFieldFullPathId === hidableFieldFullPathId ? { ...v, newVisibility: newVisibility } : { ...v },
        )
      : [...visibilitiesRef.current, { hidableFieldFullPathId, newVisibility }];
  }, []);

  const updateFieldVisibility = useCallback(
    (hidableFieldFullPathId: string, newVisibility: boolean) => {
      setDeclaration((prev) => {
        if (prev && prev.length > 0) {
          prev.forEach((root) => {
            updateVisibilityFieldOrChild(hidableFieldFullPathId, root, newVisibility);
          });
          return [...prev];
        }
        return prev;
      });
      updateVisibilityRef(hidableFieldFullPathId, newVisibility);
    },
    [setDeclaration, updateVisibilityRef],
  );

  const updateFieldReadOnly = useCallback(
    (readOnlyFieldFullPathId: string, isReadOnly: boolean) => {
      setDeclaration((prev) => {
        if (prev && prev.length > 0) {
          prev.forEach((root) => {
            updateReadOnlyFieldOrChild(readOnlyFieldFullPathId, root, isReadOnly);
          });
          return [...prev];
        }
        return prev;
      });
    },
    [setDeclaration],
  );

  const updateFieldMandatory = useCallback(
    (
      mandatoryFieldFullPathId: string,
      isMandatory: boolean,
      updateFieldErrorsCaller: (mandatoryFieldFullPathId: string, newErrors: string[]) => void,
    ) => {
      setDeclaration((prev) => {
        if (prev && prev.length > 0) {
          prev.forEach((root) => {
            // Suppression du message d'erreurs dans le cas où le mandatory est set sur false, car sinon on ne pourrait jamais valider
            const messageToRemove: string | undefined = !isMandatory
              ? declarationTranslator('errors_mandatory_field').toString()
              : undefined;

            updateMandatoryFieldOrChild(
              mandatoryFieldFullPathId,
              root,
              isMandatory,
              updateFieldErrorsCaller,
              messageToRemove,
            );
          });
          return [...prev];
        }
        return prev;
      });
    },
    [setDeclaration, declarationTranslator],
  );

  const updateItemsForThisField = useCallback(
    (fullPathId: string, nextItems: FieldDesc[]) => {
      setDeclaration((prev) => {
        if (prev && prev.length > 0) {
          prev.forEach((root) => {
            updateItemsFieldOrChild(fullPathId, root, nextItems);

            nextItems.forEach((nextItem: FieldDesc) => {
              const { items, fullPathId: fullPathIdItem } = nextItem;
              updateItemsForThisField(fullPathIdItem, items);
            });
          });
          return [...prev];
        }
        return prev;
      });
    },
    [setDeclaration],
  );

  const forceFieldUpdate = useCallback(
    (fieldPathIdToUpdate: string) => {
      setDeclaration((prev) => {
        if (prev && prev.length > 0) {
          prev.forEach((root) => {
            updateStatedIdFieldOrChild(fieldPathIdToUpdate, root);
          });
          return [...prev];
        }
        return prev;
      });
    },
    [setDeclaration],
  );

  const steps = useMemo(
    () => (formType === FormType.Workflow ? (declaration as StepDesc[]) : []),
    [declaration, formType],
  );

  useEffectOnce(() => {
    // Si un QCScript existe
    if (qcScriptObj && qcScriptObj !== '' && isEditable && qcsFormEvent) {
      const { initialization, onFormLoaded } = qcsFormEvent;
      initialization(
        context,
        qcScriptObj,
        flatten(declaration, (f: FieldDesc) => f.items),
        updateDeclaration,
        updateFieldVisibility,
        updateFieldErrors,
        updateFieldReadOnly,
        updateFieldMandatory,
      );
      onFormLoaded(flatten(declaration, (f: FieldDesc) => f.items));
      setControllerMethod('');
    }
  });

  const qcsFunctions: QCSFunctions = useMemo(
    () => ({
      updateDeclaration,
      updateFieldVisibility,
      updateFieldErrors,
      updateFieldReadOnly,
      updateFieldMandatory,
    }),
    [updateDeclaration, updateFieldVisibility, updateFieldErrors, updateFieldReadOnly, updateFieldMandatory],
  );

  const updateProperty = useCallback(
    (fullPathIdPropertyField: string, property: string, propertyValue: unknown) => {
      setDeclaration((prev) => {
        if (prev && prev.length > 0) {
          prev.forEach((field) => {
            updatePropertyFieldOrChild(fullPathIdPropertyField, field, property, propertyValue);
          });
          return [...prev];
        }
        return prev;
      });
    },
    [setDeclaration],
  );

  return {
    setCanSubmit,
    canSubmit,
    updateDeclaration,
    updateFieldVisibility,
    updateFieldReadOnly,
    updateFieldErrors,
    forceFieldUpdate,
    updateItemsForThisField,
    steps,
    qcsFormEvent,
    qcsFunctions,
    updateProperty,
  };
};

export default useData;
