import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import frLocale from 'date-fns/locale/fr';
import deLocale from 'date-fns/locale/de';
import nlLocale from 'date-fns/locale/nl';
import enLocale from 'date-fns/locale/en-US';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { DateTimeDesc, AllFieldValueTypes, FieldDesc } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { Mandatory } from '90.quickConnect.Models/enums';
import { isDate } from '80.quickConnect.Core/helpers/common';
import updateErrorsFieldOrChild from '20.formLib/helpers/updateField/updateErrorsFieldOrChild';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';

const useData = (
  dateTimeDesc: DateTimeDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  flattenFields: FieldDesc[] | undefined,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, value, min, max, mandatory, reference } = dateTimeDesc;

  const [minPick, setMinPick] = useState<Date | undefined>(() => {
    if (typeof min !== 'string') return undefined;

    const dateMin = new Date(min);

    return isNaN(dateMin.getTime()) ? undefined : dateMin;
  });
  const [maxPick, setMaxPick] = useState<Date | undefined>(() => {
    if (typeof max !== 'string') return undefined;

    const dateMax = new Date(max);

    return isNaN(dateMax.getTime()) ? undefined : dateMax;
  });
  const [localValue, setLocalValue] = useState<Date | string | null>(() => {
    if (value instanceof Date || typeof value === 'string') {
      return value;
    } else return '';
  });

  const [error, setError] = useState<boolean>(false);
  const {
    navigator: { language },
  } = window;

  const twoLetteLanguage = useMemo(() => {
    return language.substring(0, 2).toLocaleLowerCase();
  }, [language]);

  const locale = useMemo(() => {
    switch (twoLetteLanguage) {
      case 'fr':
        return frLocale;
      case 'de':
        return deLocale;
      case 'ln':
        return nlLocale;
      case 'en':
      default:
        return enLocale;
    }
  }, [twoLetteLanguage]);

  // Bug sur les placeHolder, obligés de le faire en manuel
  const placeholderDateLocale = useMemo((): string => {
    switch (twoLetteLanguage) {
      case 'fr':
        return 'dd/mm/yyyy';
      case 'de':
        return 'dd.mm.yyyy';
      case 'ln':
        return 'mm/dd/yyyy';
      case 'en':
      default:
        return 'mm/dd/yyyy';
    }
  }, [twoLetteLanguage]);

  // Bug sur les placeHolder, obligés de le faire en manuel
  const placeholderDateTimeLocale = useMemo((): string => {
    switch (twoLetteLanguage) {
      case 'fr':
        return 'dd/mm/yyyy  hh:mm';
      case 'de':
        return 'dd.mm.yyyy hh:mm';
      case 'ln':
        return 'mm/dd/yyyy hh:mm (a|p)m';
      case 'en':
      default:
        return 'mm/dd/yyyy hh:mm (a|p)m';
    }
  }, [twoLetteLanguage]);

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    if (localValue instanceof Date && !isNaN(localValue.getDate())) {
      updateDeclaration(fullPathId, localValue);
    } else if (localValue === '' || localValue == null) {
      updateDeclaration(fullPathId, null);
    } else {
      updateDeclaration(fullPathId, localValue);
    }
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const onAccept = useCallback(
    (date: Date | null) => {
      // On va set les données de l'instance de Date en lui passant une instance d'une DateExtension.
      if (date instanceof Date) {
        date.setSeconds(0);
        date.setMilliseconds(0);
        const dateTimeExt: DateTimeExtension = new DateTimeExtension(date);
        setLocalValue(dateTimeExt);
      } else {
        setLocalValue(null);
      }

      debouncedUpdateGlobalState();
      updateErrorsFieldOrChild(fullPathId, dateTimeDesc, [], t('errors_invalid_field_items'));
    },
    [dateTimeDesc, debouncedUpdateGlobalState, fullPathId, t],
  );

  const onChange = useCallback(
    (date: Date | null) => {
      // On va set les données de l'instance de Date en lui passant une instance d'une DateExtension.
      if (date instanceof Date) {
        date.setSeconds(0);
        date.setMilliseconds(0);

        // Cas des invalid Date
        const dateTimeExt: DateTimeExtension | Date = isNaN(date.getTime()) ? date : new DateTimeExtension(date);
        setLocalValue(dateTimeExt);
      } else {
        setLocalValue('');
      }
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState],
  );

  useEffect(() => {
    if (min && min?.includes('controlId')) {
      const minDate = DateTimeExtension.getDateFromControlRef(flattenFields ?? [], min);
      setMinPick(minDate);
    } else if (min && min?.includes('.')) {
      const minDate = DateTimeExtension.getDateFromSplitString(flattenFields ?? [], min);
      setMinPick(minDate);
    }

    if (max && max?.includes('controlId')) {
      const maxDate = DateTimeExtension.getDateFromControlRef(flattenFields ?? [], max);
      setMaxPick(maxDate);
    } else if (max && max?.includes('.')) {
      const maxDate = DateTimeExtension.getDateFromSplitString(flattenFields ?? [], max);
      setMaxPick(maxDate);
    }
  }, [flattenFields, fullPathId, max, min]);

  useEffect(() => {
    setError(mandatory === Mandatory.Required && (value === undefined || value === ''));
  }, [mandatory, value]);

  // UseEffect pour les références...
  useEffect(() => {
    setLocalValue((previousLocalState: string | Date | null) => {
      if (!value || value instanceof DateTimeExtension) {
        const newLocalValue = isDate(value) ? value : undefined;
        if (newLocalValue instanceof DateTimeExtension) {
          if (newLocalValue.isUTC) {
            const newLocalUtcDate = new Date(
              newLocalValue.getUTCFullYear(),
              newLocalValue.getUTCMonth(),
              newLocalValue.getUTCDate(),
              newLocalValue.getUTCHours(),
              newLocalValue.getUTCMinutes(),
              newLocalValue.getUTCSeconds(),
              newLocalValue.getUTCMilliseconds(),
            );
            return newLocalUtcDate;
          } else {
            return newLocalValue;
          }
        }
        return previousLocalState !== newLocalValue ? newLocalValue ?? null : previousLocalState;
      }

      if (typeof value === 'string') {
        const nextDate = DateTimeExtension.createLocalDateTimeFromString(value);

        return nextDate !== previousLocalState ? nextDate : nextDate;
      }

      if (value instanceof Date) {
        return isDate(value) ? (value !== previousLocalState ? value : previousLocalState) : previousLocalState;
      }

      return previousLocalState;
    });
  }, [reference, value]);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    console.log(`handleOnChangeTextInput DateQcField: event.target.value: ${event.target.value}`);
  }, []);

  // On retourne les valeurs à la vue
  return {
    handleOnChange,
    t,
    localValue,
    locale,
    maxPick,
    minPick,
    onAccept,
    onChange,
    error,
    setLocalValue,
    placeholderDateLocale,
    placeholderDateTimeLocale,
  };
};

export default useData;
