import { Breakpoints, SxProps, Button, Palette, Theme } from '@mui/material';
import styled from '@emotion/styled';
import { TopBarHeight } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';
import { dippedGreen500, slateGrey800 } from '00.app/theme';

export const DialogStyle = (breakpoints: Breakpoints, palette: Palette): SxProps => ({
  zIndex: 5,
  position: 'fixed',
  backgroundColor: palette.background.default,
  left: 0,
  bottom: 0,
  right: 0,
  minWidth: '100vw',
  padding: 0,
  justifyContent: 'center',
  margin: 0,
  height: `calc(100vh - ${TopBarHeight} - 175px)`,
  top: `calc(${TopBarHeight}px)`,
  [breakpoints.down('md')]: {
    top: `calc(${TopBarHeight}px)`,
  },
  paddingTop: '45px',
});

export const PaperPropsStyle = {
  elevation: 0,
};
export const DialogContentTitleStyle = (breakpoints: Breakpoints, isOpened?: boolean): SxProps => ({
  paddingBottom: { xs: '1em', sm: '1em', md: '2em', lg: '2em' },
  [breakpoints.up('md')]: {
    paddingLeft: isOpened ? '0vw' : '30vw',
    paddingRight: isOpened ? '50vw' : '25vw',
  },
  [breakpoints.down('md')]: {
    minWidth: '100vw',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});
export const DialogContentStyle = (breakpoints: Breakpoints, isOpened?: boolean): SxProps => ({
  overflowY: 'scroll',
  height: '80%',
  paddingBottom: { xs: '1em', sm: '1em', md: '2em', lg: '2em' },
  [breakpoints.up('md')]: {
    paddingLeft: isOpened ? '0vw' : '30vw',
    paddingRight: isOpened ? '50vw' : '25vw',
  },
  [breakpoints.down('md')]: {
    minWidth: '100vw',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

export const DialogSummaryStyle = (isReadOnly: boolean): SxProps => ({
  marginTop: isReadOnly ? '-2px' : '-3px',
  padding: '1em',
  border: isReadOnly ? `1px solid ${slateGrey800}` : `1px solid ${dippedGreen500}`,
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
});

export const DialogActionsStyle = (breakpoints: Breakpoints, palette: Palette): SxProps => ({
  backgroundColor: palette.background.default,
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: 0,
  padding: { xs: '1em', sm: '1em', md: '1.3em', lg: '1.3em' },

  right: '1em',
  [breakpoints.down('sm')]: {
    justifyContent: 'center',
    right: 0,
  },
});

export const DialogCancelButtonStyle = (breakpoints: Breakpoints, palette: Palette): SxProps => ({
  marginRight: '1em',
  [breakpoints.down('sm')]: {
    width: '45%',
  },
});

export const DialogCancelButtonCustom = styled(Button)`
  &.MuiButton-containedSecondary {
    color: ${(props) =>
      (props.theme as Theme).palette.mode === 'light'
        ? (props.theme as Theme).palette.secondary.main
        : (props.theme as Theme).palette.background.default};
    background-color: #e0e0e0;
    &:hover {
      background-color: #c5c5c5;
    }
  }
`;

export const DialogValidateButtonStyle = (breakpoints: Breakpoints, palette: Palette): SxProps => ({
  marginRight: '1em',
  color: palette.common.white,
  [breakpoints.down('sm')]: {
    width: '45%',
  },
});

export const DialogQCFieldButtonCustomized = styled(Button)`
  &.MuiButton-containedError {
    background-color: #ff6666;
  }
  &.MuiButton-containedError {
    &:hover {
      background-color: #bf4e4e;
    }
  }
`;
