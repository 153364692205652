import { SetStateAction, useMemo, useEffect, useCallback } from 'react';
import { UseDataProps } from './types';
import { FieldDesc, NumericDesc, EditDesc, NotificationDesc, CodeReaderDesc } from '90.quickConnect.Models/models';
import { Mandatory, FieldType, FormType } from '90.quickConnect.Models/enums';
import { useStore } from '30.quickConnect.Stores';
import { mapGroupIsVisibleItems } from '20.formLib/DeclarationContainer/AllResolvers/ErrorsResolver/helpers';
import { isAFieldContainer } from '90.quickConnect.Models/guards';
import { isMandatoryItems } from '90.quickConnect.Models/mappings/fields/isMandatoryItems';

/**
 * Hook qui détermine les champs à vérifier
 * @param flattenFields
 * @returns
 */
const useData = (
  flattenFields: FieldDesc[],
  setCanSubmit: (value: SetStateAction<boolean>) => void,
  formType: FormType,
  updateFieldErrors: (mandatoryFieldFullPathId: string, errors: string[]) => void,
): UseDataProps => {
  const {
    DeclarationStore: { currentStepWorkFlow, setRequiredField },
  } = useStore();

  const allFieldsToCkeck = useMemo(() => {
    if (formType === FormType.Workflow && currentStepWorkFlow) {
      const fields = flattenFields.filter(
        (f) => f.fullPathId.includes(currentStepWorkFlow.id) && f.fieldType !== FieldType.Step,
      );
      return mapGroupIsVisibleItems(fields).filter(
        (f) =>
          f.isVisible === true &&
          f.fieldType !== FieldType.CheckBox &&
          (f.mandatory === Mandatory.Required ||
            f.mandatory === Mandatory.ChildsValue ||
            ((f as NumericDesc)?.min && f.value) ||
            ((f as NumericDesc)?.max && f.value) ||
            (f.fieldType === FieldType.CodeReader && (f as CodeReaderDesc)?.mustBeOfSchema) ||
            (f.fieldType === FieldType.Text && (f as EditDesc)?.dataSource) ||
            (f.fieldType === FieldType.Notification && (f as NotificationDesc)?.dataSource) ||
            (isAFieldContainer(f) && isMandatoryItems(f))),
      );
    } else {
      return mapGroupIsVisibleItems(flattenFields).filter(
        (f) =>
          f.isVisible === true &&
          f.fieldType !== FieldType.CheckBox &&
          (f.mandatory === Mandatory.Required ||
            f.mandatory === Mandatory.ChildsValue ||
            ((f as NumericDesc)?.min && f.value) ||
            ((f as NumericDesc)?.max && f.value) ||
            (f.fieldType === FieldType.CodeReader && (f as CodeReaderDesc)?.mustBeOfSchema) ||
            (f.fieldType === FieldType.Text && (f as EditDesc)?.dataSource) ||
            (f.fieldType === FieldType.Notification && (f as NotificationDesc)?.dataSource) ||
            (isAFieldContainer(f) && isMandatoryItems(f))),
      );
    }
  }, [currentStepWorkFlow, flattenFields, formType]);

  const otherFields = useMemo(
    (): FieldDesc[] =>
      flattenFields.filter(
        (field: FieldDesc) => !allFieldsToCkeck.includes(field) && field.errors && field.errors.length > 0,
      ),
    [allFieldsToCkeck, flattenFields],
  );

  const hasItemsInErrors = useCallback(({ items, errors, isVisible }: FieldDesc): boolean => {
    if (items && items.length > 0) return items.some((f) => hasItemsInErrors(f));

    return !errors || (errors.length === 0 && !!isVisible);
  }, []);

  useEffect(() => {
    // On vérifie qu'il n'y a pas d'erreur sur un champ
    const result = allFieldsToCkeck.every(
      (f) => f.errors === undefined || f.errors.length === 0 || hasItemsInErrors(f),
    );

    otherFields.forEach(({ fullPathId: fullPathIdForOtherFields }: FieldDesc) => {
      updateFieldErrors(fullPathIdForOtherFields, []);
    });

    // Reset des erreurs pour les champs non concernés par la vérif...

    setRequiredField(allFieldsToCkeck.find((f) => f.errors && f.errors?.length > 0 && f.fieldType !== FieldType.Group));
    //setCanSubmit(result);
  }, [allFieldsToCkeck, setCanSubmit, setRequiredField, otherFields, updateFieldErrors, hasItemsInErrors]);

  return { allFieldsToCkeck };
};

export default useData;
