import React, { FunctionComponent, useState } from 'react';
import { Button, DialogContent, DialogTitle, Stack, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useTranslation } from 'react-i18next';
import { GenericIdNameAttribute, NEW_WORKSITE_DEFAULT_ID } from '../../typesWorkSite';
import { useWorkSiteContext } from '../../WorkSiteSummaryWrapper';
import CreateNewEntityDialog from './CreateNewEntityDialog';
import AddOneOrMultipleUsersDialog from './AddOneOrMultipleUsersDialog';

export type AddNewUserDialogProps = {
  onClose: () => void;
  onAdd: <T extends GenericIdNameAttribute>(listToAdd: Array<T>) => void;
  baseData: Array<GenericIdNameAttribute>;
  labels: {
    addEntity: string;
    createEntity: string;
    existingEntityTitle: string;
    newEntityTitle: string;
  };
};

export type Entity = {
  lastName?: string;
  firstName?: string;
  email?: string;
  login?: string;
  phone?: string;
};

const ChooseNewOrExistingAddDialog: FunctionComponent<AddNewUserDialogProps> = (props: AddNewUserDialogProps) => {
  const { t } = useTranslation('moduleWorkSite');
  const { workSite } = useWorkSiteContext();
  const [existingAdd, setExistingAdd] = useState<boolean>(false);
  const [newAdd, setNewAdd] = useState<boolean>(false);

  const createIsAvailable = workSite.id !== NEW_WORKSITE_DEFAULT_ID;
  return (
    <>
      {!existingAdd && !newAdd && (
        <>
          <DialogTitle>{t('work_site_entity_dialog_add_or_create_title')}</DialogTitle>
          <DialogContent>
            <Stack direction={'row'} data-cy="ChooseNewOrExistingAddDialog" gap={'1rem'}>
              <Button
                fullWidth
                variant={'contained'}
                style={{ padding: '1rem' }}
                size={'large'}
                onClick={() => setExistingAdd(true)}
              >
                <Stack gap={'0.5rem'} alignItems={'center'}>
                  <SearchIcon fontSize={'large'} />
                  {props.labels.addEntity}
                </Stack>
              </Button>
              <Tooltip
                title={createIsAvailable ? undefined : t('work_site_entity_dialog_add_or_create_disable_tooltip')}
              >
                <div style={{ width: '100%', height: '100%' }}>
                  <Button
                    fullWidth
                    variant={'outlined'}
                    style={{ padding: '1rem', minHeight: '150px' }}
                    size={'large'}
                    onClick={() => setNewAdd(true)}
                    disabled={!createIsAvailable}
                  >
                    <Stack gap={'0.5rem'} alignItems={'center'}>
                      <PersonAddAlt1Icon fontSize={'large'} />
                      {props.labels.createEntity}
                    </Stack>
                  </Button>
                </div>
              </Tooltip>
            </Stack>
          </DialogContent>
        </>
      )}
      {existingAdd && (
        <AddOneOrMultipleUsersDialog
          onClose={props.onClose}
          onAdd={props.onAdd}
          labels={props.labels}
          baseData={props.baseData}
        />
      )}
      {newAdd && (
        <CreateNewEntityDialog
          onClose={props.onClose}
          onAdd={props.onAdd}
          labels={props.labels}
          baseData={props.baseData}
        />
      )}
    </>
  );
};

export default ChooseNewOrExistingAddDialog;
