import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { MODULE_CHOICE } from '../../../routes/paths';
import {
  ForgottenPasswordMethodEnum,
  PasswordRulesType,
  SignInReducerActions,
  SignInReducerActionsType,
  UseDataProps,
} from './types';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { useStore } from '30.quickConnect.Stores';
import { ForgottenPasswordRequest, SignInRequest } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/requests';
import {
  AppModulesIDs,
  DispatcherResponse,
  ForgottenPasswordResponse,
  Modules,
} from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import { verifyResetCodeAndPassword } from '80.quickConnect.Core/helpers/verifyForm';
import { errorHandler } from '80.quickConnect.Core/helpers';

const DEFAULT_MODULE = (t: TFunction) => {
  return { [t('qcapp_web_client')]: AppModulesIDs.MODULE_WEB_CLIENT_ID };
};

const DEFAULT_SIGN_IN_REQUEST = {
  userUPN: '',
  password: '',
  cgu: false,
  module: AppModulesIDs.EMPTY_ID,
} as SignInRequest;

const reducer = (state: SignInRequest, action: SignInReducerActions) => {
  switch (action.type) {
    case SignInReducerActionsType.USERUPN:
      return { ...state, userUPN: action.value };
    case SignInReducerActionsType.PASSWORD:
      return { ...state, password: action.value };
    case SignInReducerActionsType.CGU:
      return { ...state, cgu: action.value };
    case SignInReducerActionsType.MODULE:
      return { ...state, module: action.value };
    case SignInReducerActionsType.RESET:
      return DEFAULT_SIGN_IN_REQUEST;
    default:
      return state;
  }
};

const useLoginData = (t: TFunction): UseDataProps => {
  // Tag
  const tag = '10.quickConnect.app/components/domain/Login/hooks.ts';
  // On récupère le store
  const {
    LoginStore: {
      logInAsync,
      isLogging,
      setIsLogging,
      authenticationMethod,
      verifyFieldUpnAndCgu,
      connectByMsal,
      dispatcherAsync,
      setUrlToUse,
      getAvailableMethods,
      getResetCode,
      resetCodeLoader,
      sendResetCode,
      availableModules,
      signInInfos: { userUPN, module, emailSupport },
    },
    LoginMsalStore: { handleRedirectPromise },
  } = useStore();
  const navigate = useNavigate();

  // Add defaultModule (Web Client)
  const availableModuleAndWebClient = useCallback((): Modules => {
    if (!availableModules) {
      return DEFAULT_MODULE(t);
    }
    return { ...DEFAULT_MODULE(t), ...availableModules };
  }, [availableModules, t]);

  // On set le state
  const { t: tAxios } = useTranslation('axios');
  const [passwordRules, setPasswordRules] = useState<PasswordRulesType>({
    passLength: false,
    lowerCase: false,
    upperCase: false,
    digit: false,
    specialCharacters: false,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [forgetPassword, setForgetPassword] = useState<boolean>(false);
  const [availableMethods, setAvailableMethods] = useState<ForgottenPasswordResponse>();
  const [method, setMethod] = useState<ForgottenPasswordMethodEnum>();
  const [selectMethodPage, setSelectMethodPage] = useState<boolean>(false);
  const [resetPasswordPage, setResetPasswordPage] = useState<boolean>(false);
  const [resetPasswordData, setResetPasswordData] = useState<ForgottenPasswordResponse>();
  const [signInRequest, setSignInRequest] = useReducer(reducer, { ...DEFAULT_SIGN_IN_REQUEST, userUPN: userUPN });
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [resetCode, setResetCode] = useState<string>('');
  const onForgetPassword = useCallback(async () => {
    try {
      const data = await getAvailableMethods(signInRequest.userUPN);
      if (data) {
        // Si le statut est à Pending, donc l'api a réussi à déclencher le process de reinit de mot de passe et le code du reset a été envoyé
        if (data.status == 2) {
          setResetPasswordData(data);
          setResetPasswordPage(true);
        }
        setAvailableMethods(data);
        setForgetPassword(true);
        setSelectMethodPage(true);
      }
    } catch (error: unknown) {
      errorHandler(tag, error, 'onForgetPassword');
    }
  }, [getAvailableMethods, signInRequest, tag]);

  const showPasswordField = useMemo(() => authenticationMethod === 0, [authenticationMethod]);

  const onGoBackToLogin = useCallback(() => {
    // Réinitialiser les champs
    setResetCode('');
    setNewPassword('');
    setConfirmPassword('');
    setForgetPassword(false);
    setSelectMethodPage(false);
    setResetPasswordPage(false);
    setSignInRequest({ type: SignInReducerActionsType.RESET });
  }, []);

  const onResetPassword = useCallback(async () => {
    if (verifyResetCodeAndPassword(false, resetCode, newPassword, confirmPassword, passwordRules, t)) {
      const info: ForgottenPasswordRequest = {
        userUpn: signInRequest.userUPN,
        method: method,
        newPassword,
        resetCode,
      };
      try {
        const data = await sendResetCode(info, t);
        if (data) {
          setResetCode('');
          setNewPassword('');
          setConfirmPassword('');
          setMethod(undefined);
          setForgetPassword(false);
          setSelectMethodPage(false);
          setResetPasswordPage(false);
        }
      } catch (error: unknown) {
        errorHandler(tag, error, 'onResetPassword');
      }
    }
  }, [confirmPassword, method, tag, newPassword, passwordRules, resetCode, sendResetCode, signInRequest.userUPN, t]);

  const onGoToResetPassword = useCallback(() => {
    setResetPasswordPage(true);
    setSelectMethodPage(false);
  }, []);
  const onSelectMethod = useCallback(async () => {
    try {
      const info: ForgottenPasswordRequest = {
        userUpn: signInRequest.userUPN,
        method: method,
      };
      const data = await getResetCode(info, t);
      if (data) {
        setResetPasswordData(data);
        onGoToResetPassword();
      }
    } catch (error: unknown) {
      errorHandler(tag, error, 'onSelectPassword');
    }
  }, [signInRequest.userUPN, method, getResetCode, t, onGoToResetPassword]);

  /**
   * Permet de garder le BackDrop open dans le cas d'une connexion par MSAL
   */
  const isOpenBackdrop = useMemo((): boolean => isLogging || connectByMsal, [isLogging, connectByMsal]);

  const connect = useCallback(async (): Promise<void> => {
    if (verifyFieldUpnAndCgu(signInRequest, tAxios)) {
      try {
        if (process.env.REACT_APP_ENV === 'demo') {
          await logInAsync(signInRequest, tAxios);
        } else {
          const result: DispatcherResponse | undefined = await dispatcherAsync({ userUpn: signInRequest.userUPN });
          if (result) {
            if (result.availableEnvironment.length > 1) {
              const customerNames = result.availableEnvironment.map((item) => item.customerName);
              toast.error(
                tAxios('qcapp_warning_error_config_dispatcher_multi_env', { customerName: customerNames }).toString(),
              );
              setIsLogging(false);
            } else if (
              result.availableEnvironment.length == 1 &&
              process.env.REACT_APP_QC_API_ENDPOINT?.search(
                (result.availableEnvironment[0] as any).urlQuickConnect as any,
              ) == -1
            ) {
              // dans le cas où on va changer la base url

              setUrlToUse(`${result.availableEnvironment[0].urlQuickConnect}/api/`);

              await logInAsync(signInRequest, tAxios);
            } else {
              await logInAsync(signInRequest, tAxios);
            }
          }
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          const errorAxios = error as AxiosError<any>;
          if (errorAxios?.response?.status === 404) {
            toast.error(tAxios('logInAsync_userNotFound').toString());
            setIsLogging(false);
          }
        }
      }
    }
  }, [verifyFieldUpnAndCgu, signInRequest, tAxios, logInAsync, dispatcherAsync, setIsLogging, setUrlToUse]);

  useEffectOnce(() => {
    if (connectByMsal) {
      (async () => {
        const canConnect = await handleRedirectPromise();
        if (canConnect && (module === AppModulesIDs.EMPTY_ID || module === AppModulesIDs.MODULE_WEB_CLIENT_ID)) {
          navigate(MODULE_CHOICE);
        }
      })();
    }
  });

  // on init we get the value
  useEffect(() => {
    function isEmpty(obj: any) {
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          return false;
        }
      }
      return true;
    }

    if (
      !isLogging &&
      signInRequest.module === AppModulesIDs.EMPTY_ID &&
      availableModules &&
      !isEmpty(availableModules)
    ) {
      // Get first element of availableModules returned by server or take the default result
      const key = Object.keys(availableModules).pop() || Object.keys(availableModuleAndWebClient()).pop() || '';
      const defaultSelectedModule = availableModuleAndWebClient()[key] || AppModulesIDs.EMPTY_ID;
      setSignInRequest({ type: SignInReducerActionsType.MODULE, value: defaultSelectedModule });
    }
  }, [availableModuleAndWebClient, availableModules, isLogging, signInRequest.module]);

  return {
    signInRequest,
    setSignInRequest,
    connect,
    showPassword,
    setShowPassword,
    isOpenBackdrop,
    isLogging,
    showPasswordField,
    forgetPassword,
    setForgetPassword,
    onForgetPassword,
    availableMethods,
    setMethod,
    method,
    onSelectMethod,
    resetPasswordPage,
    selectMethodPage,
    onGoBackToLogin,
    onGoToResetPassword,
    confirmPassword,
    newPassword,
    setConfirmPassword,
    setNewPassword,
    resetCode,
    setResetCode,
    passwordRules,
    setPasswordRules,
    resetPasswordData,
    onResetPassword,
    resetCodeLoader,
    availableModules: availableModuleAndWebClient(),
    currentModule: signInRequest.module,
    emailSupport,
  };
};

export default useLoginData;
